// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Proxima Nova", sans-serif;
  --content-width: 1344px;
  --container-offset: 48px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --green: #38A14C;
  --gray: #BDBDBD;
  --lightgray: #F5F5F5;
  --black: #0F181F;

  --grad1: radial-gradient(75.31% 90.45% at -2.27% 113.86%, rgba(0, 252, 101, 0.63) 0%, rgba(17, 217, 109, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(88.64% 88.64% at 10.3% 104.37%, rgba(35, 195, 255, 0.7) 29.54%, rgba(8, 135, 228, 0.07) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #192834;
  --grad2: radial-gradient(20.7% 62.14% at 83.78% 133.16%, rgba(0, 252, 101, 0.63) 0%, rgba(17, 217, 109, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(65.22% 138.41% at 84.66% 147.9%, rgba(35, 195, 255, 0.7) 29.54%, rgba(8, 135, 228, 0.07) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #0c0f11;
  
}
