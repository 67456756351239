

.header{
  position: fixed;
  width: 100%;
  z-index: 29;
  top: 0;
  left: 0;
  overflow-x: clip;
  transition-duration: 0.2s;

  &_fixed{
    background: var(--black);
  }

  &__inner{
    padding: 27px 0;
    //padding-right: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 30;
    //position: relative;

    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--grad1);
      background-size: auto 500px;
      z-index: -1;
      opacity: 0;
      transition-duration: 0.5s;
    }

    &_open::after{
      opacity: 1;
    }

    @media (max-width: 768px) {
      padding: 14px 0;
    }

  }

  &__logo{
    width: 85px;
    height: 43px;

    @media (max-width: 576px) {
      width: 45px;
      height: 23px;
    }

  }

  &__burger{
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 5px;
    width: 32px;
    height: 32px;
    transition-duration: 0.3s;

    @media (max-width: 576px) {
      display: flex;
    }

    span{
      height: 2px;
      border-radius: 10px;
      width: 100%;
      background: #fff;
      flex-shrink: 0;
      transition-duration: 0.3s;
      transform-origin: center;
    }

    &_open{
      justify-content: center;
      span:nth-of-type(1){
        margin-bottom: -1px;
        transform: rotate(45deg);
      }
      span:nth-of-type(2){
        display: none;
      }
      span:nth-of-type(3){
        margin-top: -1px;
        margin-left: -1px;
        transform: rotate(-45deg);
      }
    }

  }

  &__nav{
    display: flex;
    align-items: center;
    gap: 160px;

    @media (max-width: 991px) {
      gap: 80px;
    }

    @media (max-width: 576px) {
      position: absolute;
      background: var(--grad1);
      width: 100%;
      margin-left: -24px;
      //height: auto;
      height: 101dvh;
      overflow-y: auto;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px;
      padding-top: 100px;
      top: 0;
      z-index: -1;
      gap: 20px;
      transition-duration: 0.3s;
      transform: translateX(100%);
      opacity: 0;
    }

    &_open{
      @media (max-width: 576px) {
        transform: translateX(0);
        opacity: 1;
      }
    }

  }

  &__form{
    display: none;
    padding-bottom: 24px;
    border-top: 1px solid #ffffff44;
    padding-top: 40px;
    margin-top: 20px;

    @media (max-width: 576px) {
      display: block;
    }
  }

  &__link{
    font-size: 20px;
    color: #fff;
    transition-duration: 0.3s;
    text-transform: uppercase;

    &:hover{
      color: var(--green);
    }
  }

}