

.content{

  &_main-blog{
    //min-height: 350px;
    //position: sticky;
    //top: 700px;

  }

  &__inner{
    margin-top: 160px;
    margin-bottom: 160px;

    @media (max-width: 991px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &_article{
      margin-top: 50px;
    }

    &_mt{
      margin-top: 80px;
      @media (max-width: 576px) {
        margin-top: 60px;
      }
    }

    &_main-blog{
      transition-duration: 0.3s;
    }

    &_sticky{
      background: #1E712E;
      position: fixed;
      top: 470px;
      width: calc(100% - 48px);
    }

  }

  &__top{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__link{
    font-size: 24px;
    color: var(--black);
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  &__title{

    &_small{
      font-size: 72px;

      @media (max-width: 991px) {
        font-size: 50px;
      }
      @media (max-width: 768px) {
        font-size: 40px;
      }
      @media (max-width: 576px) {
        font-size: 30px;
      }
      @media (max-width: 390px) {
        font-size: 24px;
      }
    }
  }

  &__main-text .content-cms p{
    font-size: 18px !important;
  }

  &__box{
    margin-top: 90px;

    @media (max-width: 991px) {
      margin-top: 60px;
    }
    @media (max-width: 576px) {
      margin-top: 24px;
    }

    &_right{
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    &_main{
      @media (max-width: 768px) {
        overflow-x: auto;
        padding-bottom: 8px;
      }
    }

  }

  &__subtitle{
    font-size: 24px;
    font-weight: 600;
    margin: 24px 0;
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }

  &__article{
    width: 100%;
    max-width: 888px;

    &_margin{
      padding-top: 32px;
      padding-bottom: 32px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        padding-top: 16px;
        padding-bottom: 24px;
        margin-bottom: 0px;
      }

    }

    &_border{
      border-bottom: 1px solid #E7E7E7;
    }

    &_flex{
      display: flex;
      justify-content: space-between;
      max-width: none;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

    }

    &_big-text{
      font-size: 24px;
      line-height: 1.4;
    }

  }

  &__left{
    width: 34%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__right{
    width: 64%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__logos{
    display: flex;
    flex-wrap: wrap;
    gap: 26px;

    @media (max-width: 768px) {
      gap: 26px 4%;
    }

    &_wide{
      gap: 10px;

      @media (max-width: 768px) {
        gap: 26px 2%;
        justify-content: center;
      }
    }

  }

  &__image{
    width: 150px;
    height: 110px;
    object-fit: contain;

    @media (max-width: 768px) {
      width: 22%;
      height: 70px;
    }

    &_wide{
      width: 100%;
      /* width: 200px;
      height: 130px;

      @media (max-width: 768px) {
        width: 32%;
        height: 80px;
      } */
    }

  }


  &__video{
    width: 100%;
    height: 600px;
    background: var(--black);
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    @media (max-width: 991px) {
      height: 50vw;
    }
  }

  &__video-cover{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  &__video-start{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    margin-top: -75px;
    margin-left: -75px;
    transition-duration: 0.3s;
    z-index: 2;
    cursor: pointer;
    border-radius: 100px;

    @media (max-width: 991px) {
      width: 130px;
      height: 130px;
      margin-top: -65px;
      margin-left: -65px;
    }
    @media (max-width: 576px) {
      width: 76px;
      height: 76px;
      margin-top: -38px;
      margin-left: -38px;
    }

    &:hover{
      transform: scale(1.05);
    }

  }

  &__swiper{
    margin-bottom: 60px;

    @media (max-width: 576px) {
      margin-bottom: 30px;
    }

    &_mt{
      margin-top: 30px;
    }

  }

  &__swiper-slide{
    
    img{
      width: 100%;
      height: 500px;
      object-fit: cover;
      object-position: center;

      @media (max-width: 768px) {
        height: 60vw;
      }
    }

  }

  &__swiper-control{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__swiper-buttons{
    position: relative;
    left: 0;
    bottom: 0;
    width: 152px;
    height: 64px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) {
      width: 110px;
      height: 48px;
    }

    img{
      filter: invert(1);
    }

    .swiper-button-prev,
    .swiper-button-next{
      width: 64px;
      height: 64px;
      border-radius: 60px;
      border: 3px solid var(--green);
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      margin: 0;
      user-select: none;
      transition-duration: 0.3s;

      @media (max-width: 576px) {
        height: 48px;
        width: 48px;
      }

      &::after{
        display: none;
      }

      &:hover{
        @media (min-width: 992px) {
          border: 3px solid #1E712E;
        }
      }

    }

  }

  &__swiper-pagination{
    position: relative;
    font-size: 16px;

    .swiper-pagination{
      position: relative;
      bottom: auto;
      color: var(--gray);
      margin-top: auto;
    }

    .swiper-pagination-current{
      color: #000;
    }

  }
  
  &__filter{
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    @media (max-width: 576px) {
      gap: 5px;
    }
  }

  &__radio{
      display: none;
      &:checked + .content__label{
        background: var(--green);
        color: #fff;
      }
  }

  &__label{
    color: var(--black);
    user-select: none;
    cursor: pointer;
    font-size: 18px;
    padding: 4px 14px;
    border: 1px solid #c3e3c9;
    border-radius: 50px;
    transition-duration: 0.3s;

    @media (max-width: 576px) {
      font-size: 14px;
    }

    sup {
      font-size: 12px;
      @media (max-width: 576px) {
        font-size: 10px;
      }
    }

  }


  &__grid{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 38px;

    &_main{
      @media (max-width: 768px) {
        flex-wrap: nowrap;
        width: 130vw;
      }
      @media (max-width: 576px) {
        width: 120vw;
      }
    }
  }

  &__card{
    width: 32%;
    max-width: 436px;
    position: relative;

    @media (max-width: 768px) {
      width: 49%;
    }
    @media (max-width: 576px) {
      width: 100%;
      max-width: none;
    }

    &_blog{
      @media (max-width: 576px) {
        width: 49%;
      }
    }

    &_main{
      @media (max-width: 768px) {
        width: 42vw;
      }
      @media (max-width: 576px) {
        width: 39vw;
      }
    }

  }

  &__card-image{
    display: block;
    width: 100%;
    height: 20vw;
    object-position: center;
    border-radius: 10px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      height: 30vw;
    }
    @media (max-width: 576px) {
      margin-bottom: 10px;
    }


    &_cases{
      height: 30vw;
      max-height: 436px;
      @media (max-width: 768px) {
        height: 44vw;
      }
      @media (max-width: 576px) {
        height: 90vw;
        min-height: 272px;
        margin-bottom: 20px;
      }
    }

  }

  &__card-title{
    font-size: 24px;
    color: var(--black);
    line-height: 1.1;
    margin-bottom: 5px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
    
    &_main{
      @media (max-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    
    &_blog{
      @media (max-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

  }

  &__card-description{
    color: var(--gray);
    font-size: 16px;
    line-height: 1;
  }

  &__card-price{
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--gray);
    text-transform: uppercase;
    z-index: 1;
    padding: 4px 14px;
    background: #fff;
    border-radius: 50px;
    font-size: 18px;
    line-height: 22px;

    span{
      color: var(--black);
    }

  }

  &__card-type{
    margin-top: 10px;
    display: flex;
    gap: 6px 8px;
    flex-wrap: wrap;
  }

  &__card-tag{
    font-size: 18px;
    padding: 4px 14px;
    border-radius: 50px;
    border: 1px solid #c3e3c9;
    color: var(--black);

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__button-wrap{
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .content-cms{
    p {
      font-size: 18px;
      line-height: 1.4;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    ul, ol{
      padding-left: 25px;
    }
    li{
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 16px;
    }
  }

  &__tags{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  &__tag{
    padding: 4px 14px;
    font-size: 18px;
    color: var(--gray);
    border-radius: 50px;
    border: 1px solid #c3e3c9;
  }

  &__quote{
    border-left: 10px solid var(--green);
    padding-left: 24px;

    .content-cms{
      p{
        font-style: italic;
        font-size: 24px;
        line-height: 1.4;
        margin: 0;

        @media (max-width: 576px) {
          font-size: 18px;
        }
      }
    }

  }

  &__date{
    color: var(--gray);
    margin-bottom: 10px;
  }

  &__author{
    display: flex;
  }

  &__author-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
  }

  &__author-img{
    width: 46px;
    height: 46px;
    border-radius: 100px;
    background: var(--gray);
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-position: center;
    }

  }

  &__author-name{
    font-size: 18px;
    color: var(--black);
  }

  &__author-type{
    font-size: 16px;
    color: var(--gray);
    font-weight: 300;
  }

  &__share{
    padding: 30px 50px;
    border-radius: 10px;
    background: #E9E9E9;
    margin-top: 60px;
    display: inline-flex;
    column-gap: 50px;
    color: var(--black);
    font-size: 24px;
    line-height: 32px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      row-gap: 20px;
    }
    @media (max-width: 576px) {
      margin-top: 20px;
      padding: 16px 18px;
    }
  }

  &__socials{
    display: flex;
    align-items: center;
    column-gap: 30px;

    @media (max-width: 576px) {
      column-gap: 20px;
    }
  }

  &__socials-icon{
    width: 32px;
    height: 32px;
    path{
      transition-duration: 0.3s;
      fill: #000;
    }

    &:hover{
      path{
        fill: var(--green);
      }
    }

  }

  &__comission{
    width: 100%;
  }

  &__comission-slide{
    width: auto;
    height: 126px;
  }


}