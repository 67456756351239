

.about-first{
    width: 100%;
    background: var(--grad1);

    &__inner{
        padding-top: 180px;
        padding-bottom: 100px;

        @media (max-width: 991px) {
            padding-top: 120px;
            padding-bottom: 120px;
        }
        @media (max-width: 768px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        @media (max-width: 576px) {
            padding-bottom: 30px;
        }
    }

    &__title{
        //font-size: 136px;
        font-size: 8.8vw;
        @media (min-width: 1500px) {
            font-size: 130px;
        }
        @media (max-width: 576px) {
          font-size: 8vw;
        }
    }

    &__middle{
        position: relative;
        display: flex;
        justify-content: flex-end;
    }

    &__satellite{
        width: 50%;
        position: relative;

        @media (max-width: 991px) {
            width: 20%;
        }
    }

    &__image{
        display: block;
        position: absolute;
        object-fit: contain;
        left: -50px;
        top: -60px;
        animation: satellite 7s ease 0s;
        filter: blur(5);

        @media (max-width: 1440px) {
            left: auto;
            right: 35%;
        }
        @media (max-width: 991px) {
            width: 400px;
            right: auto;
            left: 0%;
            top: -20px;
        }
        @media (max-width: 576px) {
            width: 60%;
            right: 45%;
            left: auto;
            top: 0px;
        }

    }

    &__goods{
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 4%;
        row-gap: 30px;
        margin-top: 160px;
        margin-bottom: 230px;

        @media (max-width: 991px) {
          width: 80%;
        }
        @media (max-width: 768px) {
          margin-top: 20px;
          margin-bottom: 20px;
          width: 40%;
          z-index: 5;
        }
        @media (max-width: 576px) {
          width: 50%;
          margin-top: 0;
          row-gap: 20px;
        }

    }
    
    &__card{
        color: #fff;
        width: 28%;
        min-width: 200px;

        @media (max-width: 576px) {
          width: 100%;
          min-width: auto;
        }
    }
    
    &__text{
        font-weight: 400;

        @media (max-width: 576px) {
            font-size: 14px;
        }

        &_big{
            font-size: 60px;
            @media (max-width: 576px) {
              font-size: 20px;
              font-weight: 600;
            }
        }
        &_mid{
            font-size: 28px;
            margin-bottom: 20px;
        }
    }

    &__bottom{
      display: flex;
      justify-content: flex-end;
    }

    &__box{
        max-width: 888px;

        .content-cms{

            p{
                color: #fff;
                font-size: 18px;
                line-height: 1.3;
                @media (max-width: 768px) {
                  font-size: 14px;
                }
            }

            b{
                font-size: 24px;
                line-height: 1.3;
                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }

        }

    }

}