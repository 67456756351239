:root {
  --font-family: "Proxima Nova", sans-serif;
  --content-width: 1344px;
  --container-offset: 48px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --green: #38A14C;
  --gray: #BDBDBD;
  --lightgray: #F5F5F5;
  --black: #0F181F;
  --grad1: radial-gradient(75.31% 90.45% at -2.27% 113.86%, rgba(0, 252, 101, 0.63) 0%, rgba(17, 217, 109, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(88.64% 88.64% at 10.3% 104.37%, rgba(35, 195, 255, 0.7) 29.54%, rgba(8, 135, 228, 0.07) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #192834;
  --grad2: radial-gradient(20.7% 62.14% at 83.78% 133.16%, rgba(0, 252, 101, 0.63) 0%, rgba(17, 217, 109, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(65.22% 138.41% at 84.66% 147.9%, rgba(35, 195, 255, 0.7) 29.54%, rgba(8, 135, 228, 0.07) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #0c0f11;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/../fonts/ProximaNova-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/../fonts/ProximaNova-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: clip;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 991px) {
  .container {
    padding: 0 24px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.hidden-title {
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
}

.title {
  font-size: 96px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  .title {
    font-size: 72px;
  }
}
@media (max-width: 991px) {
  .title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 42px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 34px;
  }
}
@media (max-width: 390px) {
  .title {
    font-size: 26px;
  }
}
.title_black {
  color: var(--black);
}
.title__frame {
  display: flex;
  overflow: hidden;
}
.title__button-frame {
  position: relative;
  transition-duration: 0.7s;
  transition-delay: 1s;
  width: 0px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
}
.title__button-wrap {
  z-index: 20;
  display: flex;
  height: 63px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
  transition-duration: 0.5s;
  transition-delay: 1.2s;
  transform: scale(0);
}
@media (max-width: 991px) {
  .title__button-wrap {
    height: 48px;
    margin-bottom: 12px;
  }
}
@media (max-width: 768px) {
  .title__button-wrap {
    height: 42px;
    margin-bottom: 8px;
  }
}
@media (max-width: 650px) {
  .title__button-wrap {
    display: none;
  }
}
.title__span {
  display: block;
  opacity: 0;
  transform: translateY(140px);
  transition-duration: 0.7s;
}
.title__span_1 {
  transition-delay: 0.1s;
}
.title__span_2 {
  transition-delay: 0.2s;
}
.title__span_3 {
  transition-delay: 0.3s;
}
.title__span_4 {
  transition-delay: 0.4s;
}
.title_show .title__span {
  opacity: 1;
  transform: translateX(0);
}
.title_show .title__button-frame {
  opacity: 1;
  margin-right: 30px;
  width: 434px;
}
.title_show .title__button-wrap {
  transform: scale(1);
}

.btn {
  z-index: 20;
  font-size: 18px;
  background: #fff;
  color: var(--black);
  border-radius: 50px;
  padding: 21px;
  min-width: 434px;
  text-align: center;
  font-weight: 600;
  transition-duration: 0.3s;
}
@media (max-width: 768px) {
  .btn {
    width: 100%;
    max-width: none;
    min-width: auto;
  }
}
@media (max-width: 576px) {
  .btn {
    font-size: 14px;
    padding: 14px;
  }
}
.btn_in-text {
  min-width: auto;
  width: 100%;
  max-width: 434px;
}
@media (max-width: 991px) {
  .btn_in-text {
    padding: 14px;
  }
}
@media (max-width: 768px) {
  .btn_in-text {
    font-size: 14px;
    padding: 10px;
  }
}
.btn_black {
  background: var(--black);
  color: #fff;
}
.btn_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
}
.btn_icon img {
  transition-duration: 0.3s;
}
.btn_icon:hover img {
  filter: invert(1);
}
@media (min-width: 768px) {
  .btn:hover {
    color: #fff;
    background: var(--green);
  }
}

.link-animation {
  font-weight: 600;
  font-size: 24px;
  color: var(--black);
  position: relative;
  overflow: hidden;
  padding-bottom: 3px;
  margin-bottom: 15px;
}
.link-animation::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 100%;
  background: #000;
  left: 0;
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.5s;
}
.link-animation::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 100%;
  background: #000;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0s;
}
.link-animation:hover::before {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0s;
}
.link-animation:hover::after {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.2s;
}

.black-header .header__logo {
  filter: invert(1);
}
.black-header .header__link {
  color: var(--black);
}
.black-header .header__burger span {
  background: var(--black);
}
.black-header .header__inner_open .header__logo {
  filter: invert(0);
}
.black-header .header_fixed .header__logo {
  filter: invert(0);
}
.black-header .header_fixed .header__link {
  color: #fff;
}
.black-header .header_fixed .header__burger span {
  background: #fff;
}
.black-header .header__burger_open span {
  background: #fff;
}
.black-header .header__nav_open .header__link {
  color: #fff;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 29;
  top: 0;
  left: 0;
  overflow-x: clip;
  transition-duration: 0.2s;
}
.header_fixed {
  background: var(--black);
}
.header__inner {
  padding: 27px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 30;
}
.header__inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--grad1);
  background-size: auto 500px;
  z-index: -1;
  opacity: 0;
  transition-duration: 0.5s;
}
.header__inner_open::after {
  opacity: 1;
}
@media (max-width: 768px) {
  .header__inner {
    padding: 14px 0;
  }
}
.header__logo {
  width: 85px;
  height: 43px;
}
@media (max-width: 576px) {
  .header__logo {
    width: 45px;
    height: 23px;
  }
}
.header__burger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 5px;
  width: 32px;
  height: 32px;
  transition-duration: 0.3s;
}
@media (max-width: 576px) {
  .header__burger {
    display: flex;
  }
}
.header__burger span {
  height: 2px;
  border-radius: 10px;
  width: 100%;
  background: #fff;
  flex-shrink: 0;
  transition-duration: 0.3s;
  transform-origin: center;
}
.header__burger_open {
  justify-content: center;
}
.header__burger_open span:nth-of-type(1) {
  margin-bottom: -1px;
  transform: rotate(45deg);
}
.header__burger_open span:nth-of-type(2) {
  display: none;
}
.header__burger_open span:nth-of-type(3) {
  margin-top: -1px;
  margin-left: -1px;
  transform: rotate(-45deg);
}
.header__nav {
  display: flex;
  align-items: center;
  gap: 160px;
}
@media (max-width: 991px) {
  .header__nav {
    gap: 80px;
  }
}
@media (max-width: 576px) {
  .header__nav {
    position: absolute;
    background: var(--grad1);
    width: 100%;
    margin-left: -24px;
    height: 101dvh;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    padding-top: 100px;
    top: 0;
    z-index: -1;
    gap: 20px;
    transition-duration: 0.3s;
    transform: translateX(100%);
    opacity: 0;
  }
}
@media (max-width: 576px) {
  .header__nav_open {
    transform: translateX(0);
    opacity: 1;
  }
}
.header__form {
  display: none;
  padding-bottom: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.2666666667);
  padding-top: 40px;
  margin-top: 20px;
}
@media (max-width: 576px) {
  .header__form {
    display: block;
  }
}
.header__link {
  font-size: 20px;
  color: #fff;
  transition-duration: 0.3s;
  text-transform: uppercase;
}
.header__link:hover {
  color: var(--green);
}

.footer {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--grad1);
}
@media (max-width: 991px) {
  .footer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.footer__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2%;
}
.footer__top {
  width: 100%;
  margin-bottom: 90px;
}
@media (max-width: 768px) {
  .footer__top {
    margin-bottom: 50px;
    order: 1;
  }
}
@media (max-width: 576px) {
  .footer__top {
    margin-bottom: 24px;
  }
}
.footer__left {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer__left {
    width: 100%;
    order: 3;
  }
}
.footer__right {
  width: 53%;
  max-width: 660px;
}
@media (max-width: 768px) {
  .footer__right {
    width: 100%;
    order: 2;
    max-width: none;
  }
}
.footer__nav {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 2%;
  width: 100%;
  max-width: 430px;
}
@media (max-width: 768px) {
  .footer__nav {
    margin-top: 40px;
    gap: 16px 4%;
  }
}
@media (max-width: 576px) {
  .footer__nav {
    max-width: 600px;
  }
}
.footer__link {
  width: 49%;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  transition-duration: 0.3s;
}
@media (max-width: 991px) {
  .footer__link {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .footer__link {
    font-weight: 400;
    width: 48%;
  }
}
.footer__link:hover {
  color: var(--green);
}
.footer__contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 430px;
  column-gap: 2%;
}
@media (max-width: 768px) {
  .footer__contacts {
    margin-top: 40px;
  }
}
@media (max-width: 576px) {
  .footer__contacts {
    max-width: 600px;
  }
}
.footer__contacts-element {
  width: 49%;
}
@media (max-width: 991px) {
  .footer__contacts-element {
    font-size: 14px;
  }
}
.footer__contacts-title {
  margin-bottom: 8px;
  color: #000;
  color: rgba(255, 255, 255, 0.5);
}
.footer__contacts-link {
  color: #fff;
}
.footer__social {
  width: 100%;
  margin-top: 60px;
  display: flex;
  column-gap: 40px;
}
@media (max-width: 768px) {
  .footer__social {
    column-gap: 24px;
    margin-top: 16px;
    margin-top: 40px;
    justify-content: space-between;
    max-width: 450px;
  }
}
@media (max-width: 576px) {
  .footer__social {
    max-width: 600px;
  }
}
.footer__icon {
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 991px) {
  .footer__icon {
    width: 26px;
    height: 26px;
  }
}
.footer__icon svg {
  position: relative;
  width: 100%;
  height: 100%;
}
.footer__icon svg path {
  transition-duration: 0.3s;
}
.footer__icon:hover svg path {
  fill: var(--green);
}

.modal {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  opacity: 0;
  z-index: -1;
  transition-duration: 0.3s;
  padding: 7vh 0;
}
@media (max-width: 576px) {
  .modal {
    padding: 40px 0 100px 0;
  }
}
.modal_open {
  opacity: 1;
  z-index: 30;
}
.modal__body {
  width: 96%;
  max-width: 1080px;
  background: #fff;
  padding: 100px 50px 50px 50px;
  border-radius: 30px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .modal__body {
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .modal__body {
    padding: 30px 20px;
    border-radius: 10px;
  }
}
.modal__body_success {
  padding-top: 140px;
  padding-bottom: 140px;
}
@media (max-width: 768px) {
  .modal__body_success {
    padding: 120px 30px;
  }
}
@media (max-width: 576px) {
  .modal__body_success {
    padding: 70px 30px;
    border-radius: 10px;
  }
}
.modal__body_hide {
  display: none;
}
.modal__close {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 40px;
  right: 40px;
  padding: 5px;
  transition-duration: 0.3s;
  cursor: pointer;
  text-align: center;
}
@media (max-width: 768px) {
  .modal__close {
    top: 30px;
    right: 30px;
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 576px) {
  .modal__close {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
}
.modal__close img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}
.modal__close:hover {
  opacity: 0.5;
}
.modal__title {
  font-size: 72px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .modal__title {
    font-size: 52px;
    width: calc(100% - 60px);
  }
}
@media (max-width: 576px) {
  .modal__title {
    font-size: 29px;
  }
}
.modal__title_success {
  width: 100%;
}
.modal__title_success span {
  text-align: center;
  width: 100%;
}
.modal__text {
  font-size: 16px;
  color: var(--black);
}
.modal__text_success {
  text-align: center;
}
.modal__form {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 2%;
}
@media (max-width: 768px) {
  .modal__form {
    margin-top: 30px;
    row-gap: 21px;
  }
}
.modal__input {
  z-index: 1;
  position: relative;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  transition-duration: 0.3s;
}
.modal__input_third {
  width: 32%;
}
@media (max-width: 768px) {
  .modal__input_third {
    width: 100%;
  }
}
.modal__input_wide {
  width: 100%;
}
.modal__input_active {
  border-bottom: 1px solid var(--black);
}
.modal__input.form__input_active {
  border-bottom: 1px solid var(--black);
}
.modal__input input {
  font-size: 20px;
  color: var(--black);
  padding: 8px 0;
  padding-right: 30px;
  width: 100%;
  background: transparent;
}
.modal__input input:focus {
  outline: 0;
  outline-offset: 0;
}
.modal__files {
  position: relative;
  width: 100%;
  border-radius: 30px;
  background: #f0f0f0;
  padding: 44px 50px;
}
@media (max-width: 576px) {
  .modal__files {
    padding: 30px 20px;
    border-radius: 10px;
  }
}
.modal__files-input {
  position: absolute;
  top: -30px;
  left: 0;
  display: none;
}
.modal__files-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.modal__files-list_margin {
  margin-bottom: 40px;
}
.modal__files-line {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.modal__files-name {
  max-width: calc(100% - 50px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 440px) {
  .modal__files-name {
    width: calc(100% - 50px);
  }
}
.modal__files-del {
  margin-left: 20px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}
.modal__files-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  line-height: 30px;
}
.modal__files-label span {
  font-size: 22px;
  margin-right: 10px;
  padding-top: 3px;
  line-height: 27px;
}
@media (max-width: 576px) {
  .modal__files-label {
    font-size: 16px;
    line-height: 1.4;
  }
}
.modal__placeholder {
  display: flex;
  font-weight: 300;
  color: var(--black);
  align-items: center;
  transition-duration: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 16px;
  line-height: 20px;
}
.modal__placeholder_active {
  font-size: 16px;
  position: absolute;
  margin-top: -30px;
}
.modal__placeholder_error {
  color: #FF3B3B;
}
.modal__btn {
  width: 100%;
}
@media (max-width: 768px) {
  .modal__btn {
    min-width: auto;
    max-width: none;
  }
}
.modal__check {
  color: var(--green);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  opacity: 0;
}
.modal__check_active {
  opacity: 1;
}
.modal__note {
  color: var(--black);
  margin-top: -30px;
  font-size: 15px;
}
@media (max-width: 768px) {
  .modal__note {
    margin-top: 0;
  }
}
.modal__note_subscribe {
  margin-top: 0px;
}
.modal__note a {
  color: var(--black);
  text-decoration: underline;
}

.main-first {
  width: 100%;
  background: var(--grad1);
}
.main-first__inner {
  padding-top: 180px;
  padding-bottom: 164px;
}
@media (max-width: 991px) {
  .main-first__inner {
    padding-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .main-first__inner {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .main-first__inner {
    padding-top: 90px;
    padding-bottom: 50px;
  }
}
.main-first__title {
  font-size: 9vw;
}
@media (min-width: 1500px) {
  .main-first__title {
    font-size: 136px;
  }
}
@media (max-width: 390px) {
  .main-first__title {
    font-size: 29px;
  }
}
@media (max-width: 330px) {
  .main-first__title {
    font-size: 27px;
  }
}
.main-first__middle {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.main-first__satellite {
  width: 50%;
  position: relative;
}
@media (max-width: 1350px) {
  .main-first__satellite {
    width: 20%;
  }
}
.main-first__image {
  display: block;
  position: absolute;
  object-fit: contain;
  left: -50px;
  top: -60px;
  animation: satellite 7s ease 0s;
  filter: blur(5);
}
@media (max-width: 1440px) {
  .main-first__image {
    left: auto;
    right: 35%;
  }
}
@media (max-width: 1199px) {
  .main-first__image {
    width: 650px;
    right: 48%;
    top: -40px;
  }
}
@media (max-width: 1099px) {
  .main-first__image {
    width: 600px;
    right: 46%;
  }
}
@media (max-width: 991px) {
  .main-first__image {
    width: 54vw;
    top: -40px;
  }
}
@media (max-width: 768px) {
  .main-first__image {
    top: -30px;
  }
}
@media (max-width: 670px) {
  .main-first__image {
    width: 70vw;
    right: 31%;
    top: 0;
  }
}
@media (max-width: 390px) {
  .main-first__image {
    top: 30px;
  }
}
.main-first__goods {
  width: 50%;
  display: flex;
  column-gap: 4%;
  margin-top: 290px;
  margin-bottom: 260px;
}
@media (max-width: 1350px) {
  .main-first__goods {
    width: 80%;
    z-index: 5;
    justify-content: flex-end;
  }
}
@media (max-width: 1199px) {
  .main-first__goods {
    margin-top: 220px;
    margin-bottom: 220px;
  }
}
@media (max-width: 1099px) {
  .main-first__goods {
    margin-top: 190px;
    margin-bottom: 190px;
  }
}
@media (max-width: 991px) {
  .main-first__goods {
    margin-top: 150px;
    margin-bottom: 150px;
    column-gap: 2%;
  }
}
@media (max-width: 768px) {
  .main-first__goods {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
@media (max-width: 670px) {
  .main-first__goods {
    flex-wrap: wrap;
    width: 50%;
    margin-top: 50px;
    margin-bottom: 50px;
    row-gap: 24px;
  }
}
.main-first__card {
  color: #fff;
  width: 28%;
  min-width: 200px;
  max-width: 200px;
}
@media (max-width: 1199px) {
  .main-first__card {
    width: 22%;
    min-width: 150px;
  }
}
@media (max-width: 991px) {
  .main-first__card {
    min-width: auto;
    width: 17vw;
  }
}
@media (max-width: 670px) {
  .main-first__card {
    width: 100%;
    max-width: 200px;
  }
}
@media (max-width: 991px) {
  .main-first__card_padding {
    padding-left: 2vw;
  }
}
@media (max-width: 670px) {
  .main-first__card_padding {
    padding-left: 0;
  }
}
.main-first__text {
  font-weight: 400;
}
@media (max-width: 1099px) {
  .main-first__text {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .main-first__text {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .main-first__text {
    font-size: 10px;
  }
}
.main-first__text_big {
  font-size: 60px;
}
@media (max-width: 1350px) {
  .main-first__text_big {
    font-size: 50px;
  }
}
@media (max-width: 1099px) {
  .main-first__text_big {
    font-size: 44px;
  }
}
@media (max-width: 991px) {
  .main-first__text_big {
    font-size: 38px;
  }
}
@media (max-width: 768px) {
  .main-first__text_big {
    font-size: 32px;
  }
}
.main-first__text_mid {
  font-size: 28px;
  margin-bottom: 20px;
}
@media (max-width: 1350px) {
  .main-first__text_mid {
    font-size: 22px;
  }
}
@media (max-width: 1099px) {
  .main-first__text_mid {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .main-first__text_mid {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .main-first__text_mid {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media (max-width: 670px) {
  .main-first__text_mid {
    margin-bottom: 6px;
  }
}
.main-first__flex {
  display: flex;
  justify-content: space-between;
}
.main-first__subtitle {
  max-width: 640px;
  font-size: 7vw;
}
@media (min-width: 1360px) {
  .main-first__subtitle {
    font-size: 96px;
  }
}
.main-first__btn {
  margin-top: auto;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .main-first__btn {
    margin-bottom: 12px;
  }
}
@media (max-width: 991px) {
  .main-first__btn_second {
    font-size: 16px;
    min-width: auto;
    max-width: 350px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .main-first__btn_second {
    font-size: 14px;
    padding: 14px;
    max-width: 250px;
  }
}
@media (max-width: 576px) {
  .main-first__btn_second {
    display: none;
  }
}
.main-first__btn_mobile {
  display: none;
}
@media (max-width: 576px) {
  .main-first__btn_mobile {
    display: block;
    margin-top: 32px;
    margin-bottom: 0;
    height: 42px;
  }
}
.main-first__box {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
@media (max-width: 768px) {
  .main-first__box {
    gap: 40px;
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .main-first__box {
    gap: 24px;
  }
}
@media (max-width: 576px) {
  .main-first__box {
    margin-top: 30px;
  }
}
.main-first__line {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .main-first__line {
    flex-wrap: wrap;
    gap: 12px;
  }
}
.main-first__name {
  width: 50%;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  padding-left: 220px;
  line-height: 1;
}
@media (max-width: 1350px) {
  .main-first__name {
    font-size: 24px;
  }
}
@media (max-width: 1199px) {
  .main-first__name {
    padding-left: 150px;
    font-size: 20px;
  }
}
@media (max-width: 1099px) {
  .main-first__name {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .main-first__name {
    padding-left: 10vw;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .main-first__name {
    width: 100%;
    padding-left: 0;
    font-size: 18px;
    font-weight: 400;
  }
}
@media (max-width: 576px) {
  .main-first__name {
    font-size: 16px;
    font-weight: 400;
  }
}
.main-first__stack {
  display: flex;
  width: 50%;
  gap: 12px;
}
@media (max-width: 1366px) {
  .main-first__stack {
    gap: 10px;
  }
}
@media (max-width: 1099px) {
  .main-first__stack {
    gap: 8px;
  }
}
@media (max-width: 991px) {
  .main-first__stack {
    gap: 5px;
    width: 52%;
  }
}
@media (max-width: 767px) {
  .main-first__stack {
    width: 100%;
  }
}
.main-first__tech {
  padding: 12px 19px;
  border: 1px solid #fff;
  box-shadow: 0 0 0 0.5px #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  transition-duration: 0.3s;
}
.main-first__tech:hover {
  border: 1px solid var(--green);
  box-shadow: 0 0 0 0.5px var(--green);
}
@media (max-width: 1350px) {
  .main-first__tech {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .main-first__tech {
    padding: 10px 17px;
    font-size: 13px;
  }
}
@media (max-width: 1099px) {
  .main-first__tech {
    padding: 10px 15px;
  }
}
@media (max-width: 991px) {
  .main-first__tech {
    font-size: 10px;
    padding: 8px 12px;
  }
}
@media (max-width: 767px) {
  .main-first__tech {
    font-size: 12px;
    padding: 10px 15px;
  }
}
@media (max-width: 576px) {
  .main-first__tech {
    font-size: 10px;
    padding: 8px 12px;
    box-shadow: none;
  }
}
@media (max-width: 420px) {
  .main-first__tech {
    padding: 4px 7px;
    font-size: 9px;
    border-radius: 9px;
  }
}

@keyframes satellite {
  0% {
    transform: scale(0.4) rotate(19deg) translate(-100px, -100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  30% {
    transform: scale(0.4) rotate(19deg) translate(-100px, -100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  40% {
    transform: scale(0.4) rotate(19deg) translate(-100px, -100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  75% {
    transform: scale(1.1) rotate(-3deg) translate(0, 5px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translate(0, 0);
    filter: blur(0px);
    opacity: 1;
  }
}
.main-cases__inner {
  margin-top: 160px;
  margin-bottom: 160px;
}
@media (max-width: 991px) {
  .main-cases__inner {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .main-cases__inner {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .main-cases__inner {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.main-cases__top {
  margin-bottom: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .main-cases__top {
    margin-bottom: 60px;
    overflow-x: auto;
  }
}
@media (max-width: 576px) {
  .main-cases__top {
    margin-bottom: 32px;
  }
}
.main-cases__link {
  font-size: 24px;
  color: var(--black);
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .main-cases__link {
    margin-bottom: 0;
    font-size: 14px;
  }
}
.main-cases__swiper {
  overflow: visible;
}
.main-cases__swiper .swiper-wrapper {
  transition-timing-function: linear !important;
  cursor: grab;
}
.main-cases__slide {
  width: 435px;
}
@media (max-width: 991px) {
  .main-cases__slide {
    width: 400px;
  }
}
@media (max-width: 768px) {
  .main-cases__slide {
    width: 230px;
  }
}
@media (max-width: 576px) {
  .main-cases__slide {
    width: 170px;
  }
}
.main-cases__cover {
  width: 100%;
  height: 435px;
  border-radius: 10px;
  margin-bottom: 26px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .main-cases__cover {
    height: 400px;
  }
}
@media (max-width: 768px) {
  .main-cases__cover {
    height: 230px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .main-cases__cover {
    height: 170px;
    margin-bottom: 12px;
  }
}
.main-cases__cover img {
  width: 100%;
  height: 100%;
}
.main-cases__name {
  font-size: 20px;
  color: var(--black);
}
.main-cases__type {
  font-size: 20px;
  color: var(--gray);
}

.main-help {
  background: var(--grad2);
}
.main-help__inner {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .main-help__inner {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .main-help__inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .main-help__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.main-help__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  .main-help__top {
    flex-wrap: wrap;
  }
}
.main-help__image {
  border-radius: 10px;
  width: 45%;
  height: 181px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1099px) {
  .main-help__image {
    height: 156px;
  }
}
@media (max-width: 991px) {
  .main-help__image {
    height: 120px;
  }
}
@media (max-width: 768px) {
  .main-help__image {
    height: 90px;
  }
}
@media (max-width: 576px) {
  .main-help__image {
    width: 100%;
    height: 120px;
    margin-top: 24px;
  }
}
.main-help__image img {
  width: 100%;
  height: 100%;
}
.main-help__wrap {
  margin-top: 90px;
  position: relative;
}
@media (max-width: 991px) {
  .main-help__wrap {
    margin-top: 60px;
  }
}
@media (max-width: 576px) {
  .main-help__wrap {
    margin-top: 32px;
  }
}
.main-help__swiper {
  width: 440px;
  overflow: visible;
  position: relative;
  margin: 0;
  margin-left: 55%;
  /* &::after{
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: var(--green);
    position: absolute;
    top: 120px;
    left: 0;
  } */
}
@media (max-width: 1099px) {
  .main-help__swiper {
    margin-left: 48%;
  }
}
@media (max-width: 991px) {
  .main-help__swiper {
    margin-left: 40%;
  }
}
@media (max-width: 768px) {
  .main-help__swiper {
    margin-left: 34%;
  }
}
@media (max-width: 576px) {
  .main-help__swiper {
    margin-left: -3vw;
    width: 100%;
  }
}
.main-help__swiper .swiper-slide-active .main-help__slide-title {
  opacity: 1;
}
.main-help__swiper .swiper-slide-active .main-help__slide-text {
  opacity: 1;
}
.main-help__swiper .swiper-slide-active .main-help__slide-line {
  opacity: 1;
}
.main-help__slide {
  width: 440px;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 1099px) {
  .main-help__slide {
    width: 320px;
  }
}
@media (max-width: 991px) {
  .main-help__slide {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .main-help__slide {
    width: 80vw;
  }
}
.main-help__slide-title {
  opacity: 0.33;
  color: #fff;
  font-size: 36px;
  line-height: 1;
  transition-duration: 0.3s;
}
@media (max-width: 1099px) {
  .main-help__slide-title {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .main-help__slide-title {
    font-size: 18px;
  }
}
.main-help__slide-line {
  opacity: 0;
  margin-top: 42px;
  margin-bottom: 60px;
  width: 100px;
  height: 3px;
  background: var(--green);
  transition-duration: 0.3s;
}
@media (max-width: 1099px) {
  .main-help__slide-line {
    margin-bottom: 42px;
  }
}
@media (max-width: 991px) {
  .main-help__slide-line {
    margin-bottom: 24px;
    margin-top: 24px;
    width: 60px;
  }
}
.main-help__slide-text {
  transition-duration: 0.3s;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  line-height: 1.2;
  width: 150%;
}
@media (max-width: 1350px) {
  .main-help__slide-text {
    width: 130%;
  }
}
@media (max-width: 1099px) {
  .main-help__slide-text {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .main-help__slide-text {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .main-help__slide-text {
    width: 127%;
  }
}
@media (max-width: 576px) {
  .main-help__slide-text {
    width: calc(100vw - 60px);
  }
}
.main-help__swiper-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 152px;
  height: 64px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .main-help__swiper-buttons {
    height: 34px;
    width: 86px;
  }
}
@media (max-width: 576px) {
  .main-help__swiper-buttons {
    top: -210px;
    right: 0;
    left: auto;
  }
}
@media (max-width: 390px) {
  .main-help__swiper-buttons {
    width: 78px;
    top: -212px;
  }
}
.main-help__swiper-buttons .swiper-button-prev,
.main-help__swiper-buttons .swiper-button-next {
  width: 64px;
  height: 64px;
  border-radius: 60px;
  border: 3px solid var(--green);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  margin: 0;
  user-select: none;
  transition-duration: 0.3s;
}
@media (max-width: 991px) {
  .main-help__swiper-buttons .swiper-button-prev,
  .main-help__swiper-buttons .swiper-button-next {
    width: 34px;
    height: 34px;
    border: 2px solid var(--green);
  }
}
@media (max-width: 991px) {
  .main-help__swiper-buttons .swiper-button-prev img,
  .main-help__swiper-buttons .swiper-button-next img {
    width: 50%;
  }
}
.main-help__swiper-buttons .swiper-button-prev::after,
.main-help__swiper-buttons .swiper-button-next::after {
  display: none;
}
@media (min-width: 992px) {
  .main-help__swiper-buttons .swiper-button-prev:hover,
  .main-help__swiper-buttons .swiper-button-next:hover {
    border: 3px solid #1E712E;
  }
}

.page-first {
  width: 100%;
  background: var(--black);
  position: relative;
}
.page-first__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-first__cover img {
  object-position: center 20%;
  z-index: -1;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .page-first__cover img {
    object-position: center;
  }
}
.page-first__cover::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
.page-first__inner {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100dvh;
  max-height: 780px;
  padding-top: 120px;
  padding-bottom: 90px;
}
@media (max-width: 1199px) {
  .page-first__inner {
    padding-bottom: 70px;
  }
}
@media (max-width: 576px) {
  .page-first__inner {
    padding-bottom: 60px;
    padding-top: 75px;
    justify-content: flex-start;
    min-height: 100svh;
    height: auto;
    max-height: none;
  }
}
.page-first__track {
  color: var(--gray);
  display: flex;
  column-gap: 8px;
  position: absolute;
  left: 0;
  top: 98px;
}
@media (max-width: 768px) {
  .page-first__track {
    top: 90px;
  }
}
@media (max-width: 576px) {
  .page-first__track {
    top: 0px;
    flex-wrap: wrap;
    row-gap: 8px;
    position: relative;
    margin-bottom: 20px;
  }
}
.page-first__track a {
  color: var(--green);
}
.page-first__title {
  font-size: 72px;
}
@media (max-width: 991px) {
  .page-first__title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .page-first__title {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .page-first__title {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .page-first__title {
    font-size: 24px;
  }
}
.page-first__type, .page-first__cost {
  padding: 4px 14px;
  font-size: 18px;
  color: #fff;
  background: var(--green);
  border-radius: 50px;
  margin-bottom: 40px;
  display: inline-block;
  margin-right: auto;
}
@media (max-width: 991px) {
  .page-first__type, .page-first__cost {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .page-first__type, .page-first__cost {
    margin-bottom: 30px;
    margin-top: auto;
  }
}
.page-first__cost {
  color: var(--black);
  background: #fff;
}
.page-first__info {
  display: flex;
  justify-content: space-between;
  column-gap: 5%;
  margin-bottom: 100px;
}
@media (max-width: 1199px) {
  .page-first__info {
    flex-wrap: wrap;
    margin-bottom: 40px;
    justify-content: flex-start;
    row-gap: 10px;
  }
}
@media (max-width: 991px) {
  .page-first__info {
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .page-first__info {
    margin-bottom: 30px;
    position: absolute;
    top: 150px;
    row-gap: 15px;
  }
}
@media (max-width: 576px) {
  .page-first__info {
    top: 0;
    position: relative;
    column-gap: 4%;
    margin-bottom: 70px;
  }
}
.page-first__info-card {
  max-width: 390px;
}
@media (max-width: 576px) {
  .page-first__info-card {
    min-width: 48%;
    max-width: none;
  }
}
.page-first__info-title {
  color: #fff;
  opacity: 0.5;
  margin-bottom: 5px;
}
.page-first__info-text {
  font-size: 20px;
  color: #fff;
}
@media (max-width: 576px) {
  .page-first__info-text {
    font-size: 14px;
  }
}
.page-first__content {
  width: 100%;
}
@media (max-width: 576px) {
  .page-first__content_mt {
    margin-top: auto;
  }
}
.page-first__bot {
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
  column-gap: 48px;
  align-items: flex-end;
}
@media (max-width: 1199px) {
  .page-first__bot {
    margin-top: 32px;
  }
}
@media (max-width: 991px) {
  .page-first__bot {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .page-first__bot {
    margin-top: 20px;
  }
}
.page-first__description {
  max-width: 740px;
  font-size: 24px;
  line-height: 1.4;
  color: #fff;
}
@media (max-width: 991px) {
  .page-first__description {
    max-width: 991px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-first__description {
    font-size: 20px;
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .page-first__description {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .page-first__btn {
    min-width: 360px;
  }
}
@media (max-width: 991px) {
  .page-first__btn {
    width: 100%;
    max-width: 434px;
    margin-top: 24px;
  }
}
@media (max-width: 576px) {
  .page-first__btn {
    min-width: auto;
  }
}

.page-about {
  background: var(--grad1);
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 768px) {
  .page-about {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.page-about__inner {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .page-about__inner {
    flex-wrap: wrap;
  }
}
.page-about__left {
  width: 50%;
  max-width: 550px;
}
@media (max-width: 1199px) {
  .page-about__left {
    width: 46%;
    max-width: none;
  }
}
@media (max-width: 768px) {
  .page-about__left {
    width: 100%;
    margin-bottom: 30px;
  }
}
.page-about__right {
  width: 30%;
  min-width: 434px;
}
@media (max-width: 1199px) {
  .page-about__right {
    width: 46%;
    max-width: none;
  }
}
@media (max-width: 768px) {
  .page-about__right {
    width: 100%;
  }
}
.page-about__title {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.page-about__title_mb16 {
  margin-bottom: 16px;
}
.page-about__title_mb6 {
  margin-bottom: 6px;
}
.page-about__title_mt24 {
  margin-top: 24px;
}
.page-about .content-cms p {
  color: #fff;
  line-height: 22px;
  margin: 0;
}

.page-conditions__inner {
  margin-bottom: 160px;
}
@media (max-width: 991px) {
  .page-conditions__inner {
    margin-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .page-conditions__inner {
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .page-conditions__inner {
    margin-bottom: 60px;
  }
}
.page-conditions__title {
  font-size: 72px;
}
@media (max-width: 991px) {
  .page-conditions__title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .page-conditions__title {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .page-conditions__title {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .page-conditions__title {
    font-size: 24px;
  }
}
.page-conditions__table {
  margin-top: 90px;
}
@media (max-width: 768px) {
  .page-conditions__table {
    margin-top: 60px;
    overflow-x: auto;
  }
}
@media (max-width: 576px) {
  .page-conditions__table {
    margin-top: 32px;
  }
}
.page-conditions__table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
@media (max-width: 767px) {
  .page-conditions__table table {
    width: 800px;
  }
}
.page-conditions__table th {
  padding: 40px 16px;
  background: var(--green);
  border: 1px solid var(--green);
  color: #fff;
  text-align: left;
}
.page-conditions__table th:nth-child(1) {
  width: 30%;
}
.page-conditions__table th:nth-child(2) {
  width: 40%;
}
.page-conditions__table th:nth-child(3) {
  width: 30%;
}
.page-conditions__table td {
  padding: 16px;
  color: var(--black);
  border: 1px solid #E4E4E4;
}

.page-docs__inner {
  margin-top: 160px;
  margin-bottom: 160px;
}
@media (max-width: 991px) {
  .page-docs__inner {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .page-docs__inner {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .page-docs__inner {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.page-docs__title {
  font-size: 72px;
}
@media (max-width: 991px) {
  .page-docs__title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .page-docs__title {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .page-docs__title {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .page-docs__title {
    font-size: 24px;
  }
}
.page-docs__box {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px 20px;
}
@media (max-width: 768px) {
  .page-docs__box {
    margin-top: 60px;
    gap: 16px 4%;
  }
}
@media (max-width: 576px) {
  .page-docs__box {
    margin-top: 32px;
  }
}
.page-docs__card {
  display: flex;
  column-gap: 16px;
  align-items: center;
  width: calc(33.333% - 15px);
  cursor: pointer;
}
@media (max-width: 768px) {
  .page-docs__card {
    width: 48%;
  }
}
@media (max-width: 576px) {
  .page-docs__card {
    width: 100%;
  }
}
.page-docs__card:hover {
  /* .docs__icon path{
    fill: var(--green);
  } */
}
.page-docs__card:hover .page-docs__icon_adobe {
  background: url(/img/svg/adobe_hover.svg);
}
.page-docs__card:hover .page-docs__icon_word {
  background: url(/img/svg/word_hover.svg);
}
.page-docs__card:hover .page-docs__icon_point {
  background: url(/img/svg/point_hover.svg);
}
.page-docs__card:hover .page-docs__icon_excel {
  background: url(/img/svg/excel_hover.svg);
}
.page-docs__icon {
  width: 50px;
  height: 63px;
  transition-duration: 0.3s;
  flex-shrink: 0;
  /* path{
    transition-duration: 0.3s;
  } */
}
.page-docs__icon_adobe {
  background: url(/img/svg/adobe.svg);
}
.page-docs__icon_word {
  background: url(/img/svg/word.svg);
}
.page-docs__icon_point {
  background: url(/img/svg/point.svg);
}
.page-docs__icon_excel {
  background: url(/img/svg/excel.svg);
}
.page-docs__label {
  font-size: 18px;
  line-height: 1.3;
  color: var(--black);
  max-width: 200px;
}
@media (max-width: 991px) {
  .page-docs__label {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .page-docs__label {
    font-size: 14px;
  }
}

.sticky {
  height: 5000px;
}
@media (max-width: 768px) {
  .sticky {
    height: 5500px;
  }
}
.sticky_long {
  height: 9000px;
}
@media (max-width: 576px) {
  .sticky_long {
    height: 8000px;
  }
}
.sticky__inner {
  position: sticky;
  top: 140px;
  padding-bottom: 1px;
}
@media (max-width: 576px) {
  .sticky__inner {
    top: 100px;
  }
}

.steps {
  /* &_fixed{
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 99;
  } */
}
.steps__inner {
  margin-top: 160px;
  margin-bottom: 160px;
  position: relative;
}
@media (max-width: 991px) {
  .steps__inner {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .steps__inner {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .steps__inner {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.steps__title_sm {
  font-size: 72px;
}
@media (max-width: 991px) {
  .steps__title_sm {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .steps__title_sm {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .steps__title_sm {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .steps__title_sm {
    font-size: 24px;
  }
}
.steps__subtitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}
.steps__sticky {
  z-index: 9;
}
@media (max-width: 576px) {
  .steps__sticky {
    height: 400px;
  }
}
@media (max-width: 576px) {
  .steps__sticky_main {
    height: 360px;
  }
}
.steps__top {
  margin-bottom: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}
@media (max-width: 768px) {
  .steps__top {
    margin-bottom: 60px;
  }
}
@media (max-width: 576px) {
  .steps__top {
    margin-bottom: 32px;
  }
}
.steps__order {
  padding-bottom: 20px;
  max-width: 434px;
  height: 100%;
}
@media (max-width: 1199px) {
  .steps__order {
    padding-bottom: 0;
  }
}
@media (max-width: 991px) {
  .steps__order {
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .steps__order {
    max-width: 45%;
  }
}
@media (max-width: 991px) {
  .steps__btn {
    padding: 14px;
    font-size: 14px;
    max-width: 340px;
    min-width: auto;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .steps__btn {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .steps__btn_main {
    bottom: 0px;
  }
}
.steps__text {
  font-size: 20px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .steps__text {
    margin-bottom: 24px;
  }
}
@media (max-width: 991px) {
  .steps__text {
    max-width: 340px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .steps__text {
    font-size: 12px;
    margin-bottom: 12px;
  }
}
@media (max-width: 576px) {
  .steps__text {
    font-size: 10px;
    margin-bottom: 0;
  }
}
.steps__box {
  width: 100%;
  position: relative;
  overflow: clip;
}
.steps__floor {
  width: 100%;
}
.steps__row {
  width: 200%;
  display: flex;
  justify-content: center;
  position: relative;
  transition-duration: 0.5s;
  transform: translateX(16.6666%);
}
@media (max-width: 991px) {
  .steps__row {
    transform: translateX(0);
    width: 300%;
  }
}
@media (max-width: 450px) {
  .steps__row {
    width: 450%;
  }
}
.steps__row_step-1 {
  transform: translateX(0%);
}
@media (max-width: 576px) {
  .steps__row_step-1 {
    transform: translateX(-16.6666%);
  }
}
.steps__row_step-2 {
  transform: translateX(-16.6666%);
}
@media (max-width: 576px) {
  .steps__row_step-2 {
    transform: translateX(-33.3333%);
  }
}
.steps__row_step-3 {
  transform: translateX(-33.3333%);
}
@media (max-width: 576px) {
  .steps__row_step-3 {
    transform: translateX(-50%);
  }
}
.steps__row_step-4 {
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .steps__row_step-4 {
    transform: translateX(-66.6666%);
  }
}
.steps__progress {
  height: 1px;
  background: var(--gray);
  position: absolute;
  width: 83.3333%;
  left: 0;
  top: 123px;
  z-index: -1;
}
@media (max-width: 576px) {
  .steps__progress {
    top: 83px;
  }
}
.steps__active {
  height: 3px;
  width: 20%;
  margin-top: -1px;
  background: var(--green);
  position: absolute;
  transition-duration: 0.5s;
}
.steps__active_step-1 {
  transform: translateX(100%);
}
.steps__active_step-2 {
  transform: translateX(200%);
}
.steps__active_step-3 {
  /*       transform: translateX(203%);
        width: 24.8%; */
  transform: translateX(300%);
}
.steps__active_step-4 {
  /*       transform: translateX(303%); */
  transform: translateX(400%);
}
.steps__block {
  width: 33.333%;
}
@media (max-width: 991px) {
  .steps__block {
    width: 50%;
  }
}
@media (max-width: 450px) {
  .steps__block {
    width: 75%;
  }
}
.steps__block_50 {
  width: 50%;
}
.steps__number {
  font-size: 80px;
  line-height: 1;
  font-weight: 700;
  color: var(--black);
  transition-duration: 0.5s;
}
@media (max-width: 576px) {
  .steps__number {
    font-size: 40px;
  }
}
.steps__number_active {
  color: var(--green);
}
.steps__number span {
  font-size: 20px;
  color: var(--gray);
}
@media (max-width: 576px) {
  .steps__number span {
    font-size: 12px;
  }
}
.steps__dot {
  background: var(--green);
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-top: 36px;
  margin-bottom: 36px;
}
.steps__description {
  font-size: 20px;
  max-width: 70%;
}
@media (max-width: 768px) {
  .steps__description {
    max-width: 90%;
  }
}
@media (max-width: 576px) {
  .steps__description {
    font-size: 12px;
  }
}

.form {
  width: 100%;
}
.form__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
@media (max-width: 991px) {
  .form__top {
    margin-bottom: 42px;
  }
}
@media (max-width: 576px) {
  .form__top {
    margin-bottom: 20px;
  }
}
.form__title {
  width: 100%;
  max-width: 267px;
  color: #fff;
  font-size: 24px;
}
@media (max-width: 991px) {
  .form__title {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .form__title {
    font-size: 12px;
  }
}
.form__title_thin {
  font-weight: 300;
  font-size: 20px;
}
@media (max-width: 991px) {
  .form__title_thin {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .form__title_thin {
    font-size: 12px;
  }
}
.form__body {
  display: flex;
  flex-wrap: wrap;
  row-gap: 90px;
  column-gap: 16px;
}
@media (max-width: 991px) {
  .form__body {
    row-gap: 40px;
    column-gap: 12px;
  }
}
@media (max-width: 576px) {
  .form__body {
    row-gap: 20px;
  }
}
.form__body_subscribe {
  row-gap: 42px;
}
@media (max-width: 768px) {
  .form__body_subscribe {
    row-gap: 30px;
  }
}
.form__input {
  z-index: 1;
  position: relative;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transition-duration: 0.3s;
}
.form__input_half {
  width: calc(50% - 8px);
}
@media (max-width: 576px) {
  .form__input_half {
    width: 100%;
  }
}
.form__input_wide {
  width: 100%;
}
.form__input_active {
  border-bottom: 1px solid white;
}
.form__input input {
  font-size: 20px;
  color: #fff;
  padding: 8px 0;
  padding-right: 30px;
  width: 100%;
  background: transparent;
}
@media (max-width: 991px) {
  .form__input input {
    font-size: 12px;
  }
}
.form__input input:focus {
  outline: 0;
  outline-offset: 0;
}
.form__placeholder {
  display: flex;
  font-weight: 300;
  color: #fff;
  align-items: center;
  transition-duration: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 991px) {
  .form__placeholder {
    font-size: 12px;
  }
}
.form__placeholder_active {
  font-size: 16px;
  position: absolute;
  margin-top: -30px;
}
@media (max-width: 991px) {
  .form__placeholder_active {
    font-size: 12px;
    margin-top: -20px;
  }
}
.form__placeholder_error {
  color: #FF3B3B;
}
.form__btn {
  width: 100%;
  margin-top: 30px;
  min-width: auto;
}
@media (max-width: 991px) {
  .form__btn {
    height: 42px;
    font-size: 14px;
    padding: 12px;
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .form__btn {
    max-width: none;
  }
}
.form__check {
  color: var(--green);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  opacity: 0;
}
.form__check_active {
  opacity: 1;
}
.form__note {
  color: #fff;
  max-width: 550px;
  margin-top: -40px;
}
@media (max-width: 991px) {
  .form__note {
    font-size: 10px;
    margin-top: -20px;
  }
}
@media (max-width: 576px) {
  .form__note {
    margin-top: 0;
  }
}
.form__note_subscribe {
  margin-top: 0px;
}
.form__note a {
  color: #fff;
  text-decoration: underline;
}

.dropdown__line {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 80px;
  padding-bottom: 52px;
  padding-top: 32px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E7E7E7;
}
@media (max-width: 1199px) {
  .dropdown__line {
    column-gap: 50px;
  }
}
@media (max-width: 991px) {
  .dropdown__line {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
@media (max-width: 576px) {
  .dropdown__line {
    padding-top: 22px;
    padding-bottom: 32px;
    margin-bottom: 10px;
  }
}
.dropdown__img {
  width: 375px;
  flex-shrink: 0;
  transition-duration: 0.3s;
}
@media (max-width: 470px) {
  .dropdown__img {
    width: 100%;
  }
}
.dropdown__img img {
  transition-duration: 0.3s;
  width: 100%;
  height: 156px;
  object-fit: cover;
  object-position: right center;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .dropdown__img img {
    height: auto;
    max-height: 160px;
  }
}
.dropdown__img_open img {
  height: 350px;
}
@media (max-width: 991px) {
  .dropdown__img_open img {
    height: 260px;
    max-height: 260px;
  }
}
.dropdown__content {
  max-width: 768px;
}
@media (max-width: 991px) {
  .dropdown__content {
    width: 100%;
    padding-right: 60px;
    max-width: none;
  }
}
@media (max-width: 576px) {
  .dropdown__content {
    padding-right: 0;
  }
}
.dropdown__title {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 576px) {
  .dropdown__title {
    padding-right: 60px;
    min-height: 32px;
  }
}
.dropdown__btn {
  width: 44px;
  height: 44px;
  border-radius: 50px;
  background: #000;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
}
@media (max-width: 991px) {
  .dropdown__btn {
    position: absolute;
    right: 0;
    top: 48px;
  }
}
@media (max-width: 576px) {
  .dropdown__btn {
    width: 32px;
    height: 32px;
    top: 36px;
  }
}
@media (max-width: 991px) {
  .dropdown__btn_image {
    top: 32px;
  }
}
@media (max-width: 576px) {
  .dropdown__btn_image {
    top: 22px;
  }
}
@media (max-width: 470px) {
  .dropdown__btn_image {
    top: 200px;
  }
}
.dropdown__btn_open {
  background: var(--green);
}
.dropdown__btn_open img {
  transform: scaleY(-1);
}
.dropdown__slide {
  height: 0;
  overflow: clip;
  transition-duration: 0.3s;
}
.dropdown .content-cms p {
  color: var(--black);
  line-height: 22px;
  font-size: 16px !important;
}
.dropdown .content-cms ul li {
  margin-bottom: 0;
}
.dropdown .content-cms a {
  text-decoration: underline;
  color: var(--green);
}

.content__inner {
  margin-top: 160px;
  margin-bottom: 160px;
}
@media (max-width: 991px) {
  .content__inner {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
@media (max-width: 576px) {
  .content__inner {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.content__inner_article {
  margin-top: 50px;
}
.content__inner_mt {
  margin-top: 80px;
}
@media (max-width: 576px) {
  .content__inner_mt {
    margin-top: 60px;
  }
}
.content__inner_main-blog {
  transition-duration: 0.3s;
}
.content__inner_sticky {
  background: #1E712E;
  position: fixed;
  top: 470px;
  width: calc(100% - 48px);
}
.content__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.content__link {
  font-size: 24px;
  color: var(--black);
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .content__link {
    margin-bottom: 0;
    font-size: 14px;
  }
}
.content__title_small {
  font-size: 72px;
}
@media (max-width: 991px) {
  .content__title_small {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .content__title_small {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .content__title_small {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .content__title_small {
    font-size: 24px;
  }
}
.content__main-text .content-cms p {
  font-size: 18px !important;
}
.content__box {
  margin-top: 90px;
}
@media (max-width: 991px) {
  .content__box {
    margin-top: 60px;
  }
}
@media (max-width: 576px) {
  .content__box {
    margin-top: 24px;
  }
}
.content__box_right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .content__box_main {
    overflow-x: auto;
    padding-bottom: 8px;
  }
}
.content__subtitle {
  font-size: 24px;
  font-weight: 600;
  margin: 24px 0;
}
@media (max-width: 576px) {
  .content__subtitle {
    font-size: 20px;
  }
}
.content__article {
  width: 100%;
  max-width: 888px;
}
.content__article_margin {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .content__article_margin {
    padding-top: 16px;
    padding-bottom: 24px;
    margin-bottom: 0px;
  }
}
.content__article_border {
  border-bottom: 1px solid #E7E7E7;
}
.content__article_flex {
  display: flex;
  justify-content: space-between;
  max-width: none;
}
@media (max-width: 768px) {
  .content__article_flex {
    flex-wrap: wrap;
  }
}
.content__article_big-text {
  font-size: 24px;
  line-height: 1.4;
}
.content__left {
  width: 34%;
}
@media (max-width: 768px) {
  .content__left {
    width: 100%;
  }
}
.content__right {
  width: 64%;
}
@media (max-width: 768px) {
  .content__right {
    width: 100%;
  }
}
.content__logos {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}
@media (max-width: 768px) {
  .content__logos {
    gap: 26px 4%;
  }
}
.content__logos_wide {
  gap: 10px;
}
@media (max-width: 768px) {
  .content__logos_wide {
    gap: 26px 2%;
    justify-content: center;
  }
}
.content__image {
  width: 150px;
  height: 110px;
  object-fit: contain;
}
@media (max-width: 768px) {
  .content__image {
    width: 22%;
    height: 70px;
  }
}
.content__image_wide {
  width: 100%;
  /* width: 200px;
  height: 130px;

  @media (max-width: 768px) {
    width: 32%;
    height: 80px;
  } */
}
.content__video {
  width: 100%;
  height: 600px;
  background: var(--black);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  .content__video {
    height: 50vw;
  }
}
.content__video-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.content__video-start {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -75px;
  transition-duration: 0.3s;
  z-index: 2;
  cursor: pointer;
  border-radius: 100px;
}
@media (max-width: 991px) {
  .content__video-start {
    width: 130px;
    height: 130px;
    margin-top: -65px;
    margin-left: -65px;
  }
}
@media (max-width: 576px) {
  .content__video-start {
    width: 76px;
    height: 76px;
    margin-top: -38px;
    margin-left: -38px;
  }
}
.content__video-start:hover {
  transform: scale(1.05);
}
.content__swiper {
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .content__swiper {
    margin-bottom: 30px;
  }
}
.content__swiper_mt {
  margin-top: 30px;
}
.content__swiper-slide img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .content__swiper-slide img {
    height: 60vw;
  }
}
.content__swiper-control {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-end;
}
.content__swiper-buttons {
  position: relative;
  left: 0;
  bottom: 0;
  width: 152px;
  height: 64px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .content__swiper-buttons {
    width: 110px;
    height: 48px;
  }
}
.content__swiper-buttons img {
  filter: invert(1);
}
.content__swiper-buttons .swiper-button-prev,
.content__swiper-buttons .swiper-button-next {
  width: 64px;
  height: 64px;
  border-radius: 60px;
  border: 3px solid var(--green);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  margin: 0;
  user-select: none;
  transition-duration: 0.3s;
}
@media (max-width: 576px) {
  .content__swiper-buttons .swiper-button-prev,
  .content__swiper-buttons .swiper-button-next {
    height: 48px;
    width: 48px;
  }
}
.content__swiper-buttons .swiper-button-prev::after,
.content__swiper-buttons .swiper-button-next::after {
  display: none;
}
@media (min-width: 992px) {
  .content__swiper-buttons .swiper-button-prev:hover,
  .content__swiper-buttons .swiper-button-next:hover {
    border: 3px solid #1E712E;
  }
}
.content__swiper-pagination {
  position: relative;
  font-size: 16px;
}
.content__swiper-pagination .swiper-pagination {
  position: relative;
  bottom: auto;
  color: var(--gray);
  margin-top: auto;
}
.content__swiper-pagination .swiper-pagination-current {
  color: #000;
}
.content__filter {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .content__filter {
    gap: 5px;
  }
}
.content__radio {
  display: none;
}
.content__radio:checked + .content__label {
  background: var(--green);
  color: #fff;
}
.content__label {
  color: var(--black);
  user-select: none;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 14px;
  border: 1px solid #c3e3c9;
  border-radius: 50px;
  transition-duration: 0.3s;
}
@media (max-width: 576px) {
  .content__label {
    font-size: 14px;
  }
}
.content__label sup {
  font-size: 12px;
}
@media (max-width: 576px) {
  .content__label sup {
    font-size: 10px;
  }
}
.content__grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 38px;
}
@media (max-width: 768px) {
  .content__grid_main {
    flex-wrap: nowrap;
    width: 130vw;
  }
}
@media (max-width: 576px) {
  .content__grid_main {
    width: 120vw;
  }
}
.content__card {
  width: 32%;
  max-width: 436px;
  position: relative;
}
@media (max-width: 768px) {
  .content__card {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .content__card {
    width: 100%;
    max-width: none;
  }
}
@media (max-width: 576px) {
  .content__card_blog {
    width: 49%;
  }
}
@media (max-width: 768px) {
  .content__card_main {
    width: 42vw;
  }
}
@media (max-width: 576px) {
  .content__card_main {
    width: 39vw;
  }
}
.content__card-image {
  display: block;
  width: 100%;
  height: 20vw;
  object-position: center;
  border-radius: 10px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .content__card-image {
    height: 30vw;
  }
}
@media (max-width: 576px) {
  .content__card-image {
    margin-bottom: 10px;
  }
}
.content__card-image_cases {
  height: 30vw;
  max-height: 436px;
}
@media (max-width: 768px) {
  .content__card-image_cases {
    height: 44vw;
  }
}
@media (max-width: 576px) {
  .content__card-image_cases {
    height: 90vw;
    min-height: 272px;
    margin-bottom: 20px;
  }
}
.content__card-title {
  font-size: 24px;
  color: var(--black);
  line-height: 1.1;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .content__card-title {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .content__card-title_main {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .content__card-title_main {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .content__card-title_blog {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .content__card-title_blog {
    font-size: 14px;
  }
}
.content__card-description {
  color: var(--gray);
  font-size: 16px;
  line-height: 1;
}
.content__card-price {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--gray);
  text-transform: uppercase;
  z-index: 1;
  padding: 4px 14px;
  background: #fff;
  border-radius: 50px;
  font-size: 18px;
  line-height: 22px;
}
.content__card-price span {
  color: var(--black);
}
.content__card-type {
  margin-top: 10px;
  display: flex;
  gap: 6px 8px;
  flex-wrap: wrap;
}
.content__card-tag {
  font-size: 18px;
  padding: 4px 14px;
  border-radius: 50px;
  border: 1px solid #c3e3c9;
  color: var(--black);
}
@media (max-width: 576px) {
  .content__card-tag {
    font-size: 14px;
  }
}
.content__button-wrap {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.content .content-cms p {
  font-size: 18px;
  line-height: 1.4;
}
@media (max-width: 576px) {
  .content .content-cms p {
    font-size: 14px;
  }
}
.content .content-cms ul, .content .content-cms ol {
  padding-left: 25px;
}
.content .content-cms li {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 16px;
}
.content__tags {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.content__tag {
  padding: 4px 14px;
  font-size: 18px;
  color: var(--gray);
  border-radius: 50px;
  border: 1px solid #c3e3c9;
}
.content__quote {
  border-left: 10px solid var(--green);
  padding-left: 24px;
}
.content__quote .content-cms p {
  font-style: italic;
  font-size: 24px;
  line-height: 1.4;
  margin: 0;
}
@media (max-width: 576px) {
  .content__quote .content-cms p {
    font-size: 18px;
  }
}
.content__date {
  color: var(--gray);
  margin-bottom: 10px;
}
.content__author {
  display: flex;
}
.content__author-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
}
.content__author-img {
  width: 46px;
  height: 46px;
  border-radius: 100px;
  background: var(--gray);
  overflow: hidden;
}
.content__author-img img {
  width: 100%;
  height: 100%;
  object-position: center;
}
.content__author-name {
  font-size: 18px;
  color: var(--black);
}
.content__author-type {
  font-size: 16px;
  color: var(--gray);
  font-weight: 300;
}
.content__share {
  padding: 30px 50px;
  border-radius: 10px;
  background: #E9E9E9;
  margin-top: 60px;
  display: inline-flex;
  column-gap: 50px;
  color: var(--black);
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 768px) {
  .content__share {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    row-gap: 20px;
  }
}
@media (max-width: 576px) {
  .content__share {
    margin-top: 20px;
    padding: 16px 18px;
  }
}
.content__socials {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
@media (max-width: 576px) {
  .content__socials {
    column-gap: 20px;
  }
}
.content__socials-icon {
  width: 32px;
  height: 32px;
}
.content__socials-icon path {
  transition-duration: 0.3s;
  fill: #000;
}
.content__socials-icon:hover path {
  fill: var(--green);
}
.content__comission {
  width: 100%;
}
.content__comission-slide {
  width: auto;
  height: 126px;
}

.subscribe {
  background: var(--grad1);
}
.subscribe__inner {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 768px) {
  .subscribe__inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 576px) {
  .subscribe__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.subscribe__title {
  color: #fff;
  font-size: 72px;
}
@media (max-width: 1299px) {
  .subscribe__title {
    font-size: 50px;
  }
}
@media (max-width: 991px) {
  .subscribe__title {
    font-size: 44px;
  }
}
@media (max-width: 768px) {
  .subscribe__title {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .subscribe__title {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .subscribe__title {
    font-size: 24px;
  }
}
.subscribe__about {
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  margin-top: 22px;
  line-height: 1.2;
}
@media (max-width: 576px) {
  .subscribe__about {
    font-size: 18px;
  }
}
.subscribe__box {
  display: flex;
  justify-content: space-between;
  column-gap: 4%;
}
@media (max-width: 768px) {
  .subscribe__box {
    flex-wrap: wrap;
  }
}
.subscribe__left {
  width: 48%;
}
@media (max-width: 768px) {
  .subscribe__left {
    width: 100%;
    margin-bottom: 60px;
  }
}
.subscribe__right {
  width: 48%;
}
@media (max-width: 768px) {
  .subscribe__right {
    width: 100%;
  }
}

.gg-container {
  --main-color: #000;
  --secondary-color: #111;
  --txt-color: #fff;
  --img-bg-color: rgba(240, 240, 240, 0.9);
  --backdrop-color: rgba(240, 240, 240, 0.9);
  --gap-length: 2px;
  --row-height: 200px;
  --column-width: 220px;
}

.gg-container *[data-theme=dark] {
  --main-color: #ddd;
  --secondary-color: #eee;
  --txt-color: #111;
  --img-bg-color: rgba(20, 20, 20, 0.9);
  --backdrop-color: rgba(30, 30, 30, 0.9);
}

.gg-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
  grid-auto-rows: var(--row-height);
  grid-gap: var(--gap-length);
  margin: 20px 0;
}
@media (max-width: 768px) {
  .gg-box {
    display: flex;
    flex-wrap: wrap;
  }
}

.gg-box img {
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: var(--img-bg-color);
}
@media (max-width: 768px) {
  .gg-box img {
    max-height: 400px;
  }
}
@media (max-width: 576px) {
  .gg-box img {
    max-height: 40vw;
  }
}
@media (max-width: 768px) {
  .gg-box img:nth-of-type(1) {
    max-height: 700px;
  }
}
@media (max-width: 576px) {
  .gg-box img:nth-of-type(1) {
    max-height: 70vw;
  }
}

.gg-box img:hover {
  opacity: 0.98;
}

#gg-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--backdrop-color);
  z-index: 9999;
  text-align: center;
}

#gg-screen .gg-image {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#gg-screen .gg-image img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.gg-btn {
  width: 35px;
  height: 35px;
  background: var(--main-color);
  color: var(--txt-color);
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 20px;
  box-sizing: border-box;
  padding-left: 2px;
  position: fixed;
  bottom: 10px;
}

.gg-btn:hover {
  background: var(--secondary-color);
}

.gg-close {
  top: 10px;
}

.gg-close,
.gg-next {
  right: 10px;
}

.gg-prev {
  right: 50px;
}

.gg-prev,
.gg-next {
  bottom: 10px;
}

@media (min-width: 478px) {
  .gg-box img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 2;
  }
  [data-layout=horizontal] img:nth-child(2n):not(:last-of-type) {
    grid-column-end: span 2;
    grid-row-end: span 1;
  }
  [data-layout=square] img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 1;
    grid-column-end: span 1;
  }
}
@media (max-width: 768px) {
  .gg-box {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: calc(var(--row-height) - 15vh);
    margin: 10px 0;
  }
}
@media (max-width: 450px) {
  .gg-box {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}
.about-first {
  width: 100%;
  background: var(--grad1);
}
.about-first__inner {
  padding-top: 180px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .about-first__inner {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .about-first__inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .about-first__inner {
    padding-bottom: 30px;
  }
}
.about-first__title {
  font-size: 8.8vw;
}
@media (min-width: 1500px) {
  .about-first__title {
    font-size: 130px;
  }
}
@media (max-width: 576px) {
  .about-first__title {
    font-size: 8vw;
  }
}
.about-first__middle {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.about-first__satellite {
  width: 50%;
  position: relative;
}
@media (max-width: 991px) {
  .about-first__satellite {
    width: 20%;
  }
}
.about-first__image {
  display: block;
  position: absolute;
  object-fit: contain;
  left: -50px;
  top: -60px;
  animation: satellite 7s ease 0s;
  filter: blur(5);
}
@media (max-width: 1440px) {
  .about-first__image {
    left: auto;
    right: 35%;
  }
}
@media (max-width: 991px) {
  .about-first__image {
    width: 400px;
    right: auto;
    left: 0%;
    top: -20px;
  }
}
@media (max-width: 576px) {
  .about-first__image {
    width: 60%;
    right: 45%;
    left: auto;
    top: 0px;
  }
}
.about-first__goods {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  row-gap: 30px;
  margin-top: 160px;
  margin-bottom: 230px;
}
@media (max-width: 991px) {
  .about-first__goods {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .about-first__goods {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40%;
    z-index: 5;
  }
}
@media (max-width: 576px) {
  .about-first__goods {
    width: 50%;
    margin-top: 0;
    row-gap: 20px;
  }
}
.about-first__card {
  color: #fff;
  width: 28%;
  min-width: 200px;
}
@media (max-width: 576px) {
  .about-first__card {
    width: 100%;
    min-width: auto;
  }
}
.about-first__text {
  font-weight: 400;
}
@media (max-width: 576px) {
  .about-first__text {
    font-size: 14px;
  }
}
.about-first__text_big {
  font-size: 60px;
}
@media (max-width: 576px) {
  .about-first__text_big {
    font-size: 20px;
    font-weight: 600;
  }
}
.about-first__text_mid {
  font-size: 28px;
  margin-bottom: 20px;
}
.about-first__bottom {
  display: flex;
  justify-content: flex-end;
}
.about-first__box {
  max-width: 888px;
}
.about-first__box .content-cms p {
  color: #fff;
  font-size: 18px;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .about-first__box .content-cms p {
    font-size: 14px;
  }
}
.about-first__box .content-cms b {
  font-size: 24px;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .about-first__box .content-cms b {
    font-size: 18px;
  }
}

.about-trust {
  background: var(--black);
}
.about-trust__inner {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .about-trust__inner {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media (max-width: 576px) {
  .about-trust__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.about-trust__title {
  font-size: 72px;
}
@media (max-width: 991px) {
  .about-trust__title {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .about-trust__title {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .about-trust__title {
    font-size: 30px;
  }
}
@media (max-width: 390px) {
  .about-trust__title {
    font-size: 24px;
  }
}
.about-trust__swiper {
  overflow: visible;
  margin-top: 40px;
}
@media (max-width: 991px) {
  .about-trust__swiper {
    margin-top: 30px;
  }
}
.about-trust__swiper .swiper-wrapper {
  transition-timing-function: linear !important;
  cursor: grab;
}
.about-trust__slide {
  width: 100%;
  max-width: 230px;
  height: 168px;
}
@media (max-width: 576px) {
  .about-trust__slide {
    max-width: 96px;
    height: 70px;
  }
}
.about-trust__slide img {
  width: 100%;
  height: 100%;
}

.error-first {
  position: relative;
  background: var(--grad1);
}
.error-first__inner {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-first__text {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}
.error-first__text_big {
  font-size: 24px;
  font-weight: 600;
}
.error-first__title {
  font-size: 246px;
  text-align: center;
}
@media (max-width: 576px) {
  .error-first__title {
    font-size: 150px;
  }
}
.error-first__cases {
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  cursor: pointer;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0 20px;
}
@media (max-width: 576px) {
  .error-first__cases {
    max-width: 290px;
    font-size: 16px;
    bottom: 24px;
  }
}

.error-cases__inner {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.error-cases__row {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .error-cases__row {
    flex-wrap: wrap;
  }
}
.error-cases__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 40px;
}
@media (max-width: 991px) {
  .error-cases__card {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .error-cases__card {
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .error-cases__card {
    padding: 20px;
  }
}
.error-cases__card_square {
  width: 41.66666vw;
  max-width: 800px;
  height: 41.66667vw;
  max-height: 800px;
}
@media (max-width: 768px) {
  .error-cases__card_square {
    width: 100vw;
    height: 100vw;
    max-width: 991px;
    max-height: 991px;
  }
}
.error-cases__card_wide {
  width: 58.33334vw;
  max-width: 1120px;
  height: 20.833334vw;
  max-height: 400px;
}
@media (max-width: 768px) {
  .error-cases__card_wide {
    width: 100vw;
    height: 50vw;
    max-width: 991px;
    max-height: 300px;
    min-height: 300px;
  }
}
@media (max-width: 576px) {
  .error-cases__card_wide {
    min-height: 260px;
  }
}
.error-cases__card_more {
  width: 41.66666vw;
  max-width: 800px;
  height: 20.833334vw;
  max-height: 400px;
  background: #fff;
  align-items: center;
  padding: 0;
}
@media (max-width: 768px) {
  .error-cases__card_more {
    width: 100vw;
    height: 300px;
    max-width: 991px;
  }
}
@media (max-width: 576px) {
  .error-cases__card_more {
    height: 320px;
  }
}
.error-cases__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: top;
  z-index: -1;
}
.error-cases__more {
  border-radius: 200px;
  height: 20.833334vw;
  max-height: 400px;
  width: 20.833334vw;
  max-width: 400px;
  background: linear-gradient(214.8deg, #38A14C -2.06%, #126D81 84.01%);
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .error-cases__more {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 576px) {
  .error-cases__more {
    width: 320px;
    height: 320px;
  }
}
.error-cases__type {
  color: #fff;
  font-size: 24px;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 4px 14px;
  display: inline-flex;
  margin-right: auto;
}
.error-cases__name {
  font-size: 64px;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .error-cases__name {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .error-cases__name {
    font-size: 36px;
  }
}