

.page-first{
  width: 100%;
  background: var(--black);
  position: relative;

  &__cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba($color: #000000, $alpha: 0.8);

    img{
      object-position: center 20%;
      z-index: -1;
      width: 100%;
      height: 100%;
      
      @media (max-width: 991px) {
        object-position: center;
      }
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($color: #000000, $alpha: 0.8);
      z-index: 1;
    }

  }

  

  &__inner{
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100dvh;
    max-height: 780px;
    padding-top: 120px;
    padding-bottom: 90px;

    @media (max-width: 1199px) {
      padding-bottom: 70px;
    }
    @media (max-width: 991px) {
      //max-height: 100dvh;
    }
    @media (max-width: 576px) {
      //max-height: 500px;
      padding-bottom: 60px;
      padding-top: 75px;
      //justify-content: space-between;
      justify-content: flex-start;
      min-height: 100svh;
      height: auto;
      max-height: none;
    }
  }

  &__track{
    color: var(--gray);
    display: flex;
    column-gap: 8px;
    position: absolute;
    left: 0;
    top: 98px;

    @media (max-width: 768px) {
      top: 90px;
    }
    @media (max-width: 576px) {
      top: 0px;
      flex-wrap: wrap;
      row-gap: 8px;
      position: relative;
      margin-bottom: 20px;
    }

    span{
      //white-space: nowrap;
    }

    a{
      color: var(--green);
    }
  }

  &__title{
    font-size: 72px;

    @media (max-width: 991px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
    }
    @media (max-width: 390px) {
      font-size: 24px;
    }
  }

  &__type,
  &__cost{
    padding: 4px 14px;
    font-size: 18px;
    color: #fff;
    background: var(--green);
    border-radius: 50px;
    margin-bottom: 40px;
    display: inline-block;
    margin-right: auto;

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
      margin-top: auto;
    }
  }

  &__cost{
    color: var(--black);
    background: #fff;
  }

  &__info{
    display: flex;
    justify-content: space-between;
    column-gap: 5%;
    margin-bottom: 100px;
    @media (max-width: 1199px) {
      flex-wrap: wrap;
      margin-bottom: 40px;
      justify-content: flex-start;
      row-gap: 10px;
    }
    @media (max-width: 991px) {
      margin-bottom: 80px;
    }
    @media (max-width: 768px) {
      margin-bottom: 30px;
      position: absolute;
      top: 150px;
      row-gap: 15px;
    }
    @media (max-width: 576px) {
      top: 0;
      position: relative;
      column-gap: 4%;
      margin-bottom: 70px;
    }
  }

  &__info-card{
    max-width: 390px;
    @media (max-width: 576px) {
      min-width: 48%;
      max-width: none;
    }
  }

  &__info-title{
    color: #fff;
    opacity: 0.5;
    margin-bottom: 5px;
  }

  &__info-text{
    font-size: 20px;
    color: #fff;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }


  &__content{
    width: 100%;

    &_mt{
      @media (max-width: 576px) {
        margin-top: auto;
      }  
    }
    
  }

  &__bot{
    display: flex;
    margin-top: 48px;
    justify-content: space-between;
    column-gap: 48px;
    align-items: flex-end;

    @media (max-width: 1199px) {
      margin-top: 32px;
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin-top: 20px;
    }
    @media (max-width: 576px) {
      margin-top: 20px;
    }
  }

  &__description{
    max-width: 740px;
    font-size: 24px;
    line-height: 1.4;
    color: #fff;

    @media (max-width: 991px) {
      max-width: 991px;
      width: 100%;
    }
    @media (max-width: 768px) {
      font-size: 20px;
      max-width: 600px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__btn{
    @media (max-width: 1199px) {
      min-width: 360px;
    }
    @media (max-width: 991px) {
      width: 100%;
      max-width: 434px;
      margin-top: 24px;
    }
    @media (max-width: 576px) {
      min-width: auto;
    }
  }


}