

.main-cases{
  

  &__inner{
    margin-top: 160px;
    margin-bottom: 160px;

    @media (max-width: 991px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 768px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &__top{
    margin-bottom: 90px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 768px) {
      margin-bottom: 60px;
      overflow-x: auto;
    }
    @media (max-width: 576px) {
      margin-bottom: 32px;
    }
  }

  &__link{
    font-size: 24px;
    color: var(--black);
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  &__swiper{
    overflow: visible;

    .swiper-wrapper{
      transition-timing-function: linear !important;
      cursor: grab;
    }
  }

  &__slide{
    width: 435px;

    @media (max-width: 991px) {
      width: 400px;
    }
    @media (max-width: 768px) {
      width: 230px;
    }
    @media (max-width: 576px) {
      width: 170px;
    }
  }

  &__cover{
    width: 100%;
    height: 435px;
    border-radius: 10px;
    margin-bottom: 26px;
    overflow: hidden;

    @media (max-width: 991px) {
      height: 400px;
    }
    @media (max-width: 768px) {
      height: 230px;
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      height: 170px;
      margin-bottom: 12px;
    }

    
    img{
      width: 100%;
      height: 100%;
    }

  }

  &__name{
    font-size: 20px;
    color: var(--black);
  }

  &__type{
    font-size: 20px;
    color: var(--gray);
  }


}


