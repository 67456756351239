



.page-about{
  background: var(--grad1);
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &__inner{
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  &__left{
    width: 50%;
    max-width: 550px;
    @media (max-width: 1199px) {
      width: 46%;
      max-width: none;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__right{
    width: 30%;
    min-width: 434px;
    @media (max-width: 1199px) {
      width: 46%;
      max-width: none;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__title{
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600;
    color: #fff;
    margin: 0;
    
    &_mb16{
      margin-bottom: 16px;
    }

    &_mb6{
      margin-bottom: 6px;
    }

    &_mt24{
      margin-top: 24px;
    }

  }

  .content-cms{
    p{
      color: #fff;
      line-height: 22px;
      margin: 0;
    }
  }

}