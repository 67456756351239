

.subscribe{

  background: var(--grad1);

  &__inner{
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 768px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media (max-width: 576px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &__title{
    color: #fff;
    font-size: 72px;

  
    @media (max-width: 1299px) {
      font-size: 50px;
    }
    @media (max-width: 991px) {
      font-size: 44px;
    }
    @media (max-width: 768px) {
      font-size: 50px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
    }
    @media (max-width: 390px) {
      font-size: 24px;
    }
  }

  &__about{
    font-weight: 300;
    font-size: 20px;
    color: #fff;
    margin-top: 22px;
    line-height: 1.2;
    @media (max-width: 576px) {
      font-size: 18px;  
    }
  }

  &__box{
    display: flex;
    justify-content: space-between;
    column-gap: 4%;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  &__left{
    width: 48%;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 60px;
    }
  }

  &__right{
    width: 48%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

}