

.about-trust{

  background: var(--black);

  &__inner{
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 991px) {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    @media (max-width: 576px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

  }

  &__title{
    font-size: 72px;

    @media (max-width: 991px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
    }
    @media (max-width: 390px) {
      font-size: 24px;
    }
  }

  &__swiper{
    overflow: visible;
    margin-top: 40px;

    @media (max-width: 991px) {
      margin-top: 30px;
    }

    .swiper-wrapper{
      transition-timing-function: linear !important;
      cursor: grab;
    }
  }

  &__slide{
    width: 100%;
    max-width: 230px;
    height: 168px;

    @media (max-width: 576px) {
      max-width: 96px;
      height: 70px;
    }

    img{
      width: 100%;
      height: 100%;
    }

  }

}