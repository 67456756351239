

.page-conditions{

  &__inner{
    //margin-top: 160px;
    margin-bottom: 160px;

    @media (max-width: 991px) {
      //margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 768px) {
        //margin-top: 80px;
        margin-bottom: 80px;
    }
    @media (max-width: 576px) {
      //margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &__title{
    font-size: 72px;

    @media (max-width: 991px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
    }
    @media (max-width: 390px) {
      font-size: 24px;
    }
  }

  &__table{
    margin-top: 90px;

    @media (max-width: 768px) {
      margin-top: 60px;
      overflow-x: auto;
    }
    @media (max-width: 576px) {
      margin-top: 32px;
    }

    table{
      width: 100%; 
      border-spacing: 0;
      border-collapse: collapse;

      @media (max-width: 767px) {
        width: 800px;
      }

    }

    th{
      padding: 40px 16px;
      background: var(--green);
      border: 1px solid var(--green);
      color: #fff;
      text-align: left;

      &:nth-child(1){
        width: 30%;
      }
      &:nth-child(2){
        width: 40%;
      }
      &:nth-child(3){
        width: 30%;
      }
    }

    td{
      padding: 16px;
      color: var(--black);
      border: 1px solid #E4E4E4;
    }

  }

}