

.page-docs{
  
  &__inner{
    margin-top: 160px;
    margin-bottom: 160px;

    @media (max-width: 991px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 768px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &__title{
    font-size: 72px;

    @media (max-width: 991px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
    }
    @media (max-width: 390px) {
      font-size: 24px;
    }
  }

  &__box{
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px 20px;

    @media (max-width: 768px) {
      margin-top: 60px;
      gap: 16px 4%;
    }
    @media (max-width: 576px) {
      margin-top: 32px;
    }
  }

  &__card{
    display: flex;
    column-gap: 16px;
    align-items: center;
    width: calc(33.333% - 15px);
    cursor: pointer;

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }

    &:hover{
      /* .docs__icon path{
        fill: var(--green);
      } */
      .page-docs__icon_adobe{
        background: url(/img/svg/adobe_hover.svg);
      }
      .page-docs__icon_word{
        background: url(/img/svg/word_hover.svg);
      }
      .page-docs__icon_point{
        background: url(/img/svg/point_hover.svg);
      }
      .page-docs__icon_excel{
        background: url(/img/svg/excel_hover.svg);
      }
    }
  }

  &__icon{
    width: 50px;
    height: 63px;
    transition-duration: 0.3s;
    flex-shrink: 0;

    /* path{
      transition-duration: 0.3s;
    } */
    &_adobe{
      background: url(/img/svg/adobe.svg);
    }
    &_word{
      background: url(/img/svg/word.svg);
    }
    &_point{
      background: url(/img/svg/point.svg);
    }
    &_excel{
      background: url(/img/svg/excel.svg);
    }
  }

  &__label{
    font-size: 18px;
    line-height: 1.3;
    color: var(--black);
    max-width: 200px;

    @media (max-width: 991px) {
      font-size: 16px;
    }

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  
}