html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  //overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  overflow: clip;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;


  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);

  @media (max-width: 991px) {
    padding: 0 24px;
  }

}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}



.hidden-title{
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
}

.title{
  font-size: 96px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 72px;
  }
  @media (max-width: 991px) {
    font-size: 50px;
  }
  @media (max-width: 768px) {
    font-size: 42px;
  }
  @media (max-width: 576px) {
    font-size: 34px;
  }
  @media (max-width: 390px) {
    font-size: 26px;
  }


  &_black{
    color: var(--black);
  }

  &__frame{
    display: flex;
    overflow: hidden;
    //column-gap: 3%;
  }

  &__button-frame{
    position: relative;
    
    transition-duration: 0.7s;
    transition-delay: 1s;
    width: 0px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }

  &__button-wrap{
    z-index: 20;
    display: flex;
    height: 63px;
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;
    //position: absolute;
    //bottom: 24px;
    transition-duration: .5s;
    transition-delay: 1.2s;
    transform: scale(0);
    @media (max-width: 991px) {
      height: 48px;
      margin-bottom: 12px;
    }
    @media (max-width: 768px) {
      height: 42px;
      margin-bottom: 8px;
    }
    @media (max-width: 650px) {
      display: none;
    }
  }

  &__span{ 
    display: block;
    opacity: 0;
    transform: translateY(140px);
    transition-duration: 0.7s;
    &_1{transition-delay: 0.1s;}
    &_2{transition-delay: 0.2s;}
    &_3{transition-delay: 0.3s;}
    &_4{transition-delay: 0.4s;}
  }

  &_show{
    .title__span{
      opacity: 1;
      transform: translateX(0);
    }
    .title__button-frame{
      opacity: 1;
      margin-right: 30px;
      width: 434px;
    }
    .title__button-wrap{
      transform: scale(1);
    }
  }

}

.btn{
  z-index: 20;
  font-size: 18px;
  background: #fff;
  color: var(--black);
  border-radius: 50px;
  padding: 21px;
  min-width: 434px;
  text-align: center;
  font-weight: 600;
  transition-duration: 0.3s;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    min-width: auto;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    padding: 14px;
  }

  &_in-text{
    min-width: auto;
    width: 100%;
    max-width: 434px;
    @media (max-width: 991px) {
      padding: 14px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 10px;
    }
  }

  &_black{
    background: var(--black);
    color: #fff;
  }

  &_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 14px;

    img{
      transition-duration: 0.3s;
    }

    &:hover{
      img{
        filter: invert(1);
      }
    }

  }

  &:hover{
    @media (min-width: 768px) {
      color: #fff;
      background: var(--green);
    }
  }



}

.link-animation{
  font-weight: 600;
  font-size: 24px;
  color: var(--black);
  position: relative;
  overflow: hidden;
  padding-bottom: 3px;
  margin-bottom: 15px;

  &::before{
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: 100%;
    background: #000;
    left: 0;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform .55s cubic-bezier(.785,.135,.15,.86);
    transition-delay: .5s;
  }

  &::after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    height: 1px;
    width: 100%;
    background: #000;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .55s cubic-bezier(.785,.135,.15,.86);
    transition-delay: 0s;
  }

  &:hover{
    &::before{
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .55s cubic-bezier(.785,.135,.15,.86);
      transition-delay: 0s;
    }
    &::after{
      transform: scaleX(1);
      transform-origin: left;
      transition: transform .55s cubic-bezier(.785,.135,.15,.86);
      transition-delay: .2s;
    }
  }
}

.black-header{

  .header{
    //position: relative;
  }

  .header__logo{
    filter: invert(1);
  }

  .header__link{
    color: var(--black);
  }

  .header__burger span{
    background: var(--black);
  }

  .header__inner_open{
    .header__logo{
      filter: invert(0);
    }
  }

  .header_fixed{
    .header__logo{
      filter: invert(0);
    }
    .header__link{
      color: #fff;
    }
    .header__burger span{
      background: #fff;
    }
  }

  .header__burger_open span{
    background: #fff;
  }
  .header__nav_open{
    .header__link{
      color: #fff;
    }
  }

}