

.main-help{
  background: var(--grad2);

  &__inner{
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 991px) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @media (max-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (max-width: 576px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    //position: relative;

    @media (max-width: 576px) {
      flex-wrap: wrap;
    }
  }

  &__image{
    border-radius: 10px;
    width: 45%;
    height: 181px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: 1099px) {
      height: 156px;
    }
    @media (max-width: 991px) {
      height: 120px;
    }
    @media (max-width: 768px) {
      height: 90px;
    }
    @media (max-width: 576px) {
      width: 100%;
      height: 120px;
      margin-top: 24px;
    }
    
    img{
      width: 100%;
      height: 100%;
    }
  }

  &__wrap{
    //background: #f99;
    margin-top: 90px;
    position: relative;

    @media (max-width: 991px) {
      margin-top: 60px;
    }
    @media (max-width: 576px) {
      margin-top: 32px;
    }
  }

  &__swiper{
    width: 440px;
    overflow: visible;
    position: relative;
    margin: 0;
    margin-left: 55%;

    @media (max-width: 1099px) {
      margin-left: 48%;
    }
    @media (max-width: 991px) {
      margin-left: 40%;
    }
    @media (max-width: 768px) {
      margin-left: 34%;
    }
    @media (max-width: 576px) {
      margin-left: -3vw;
      width: 100%;
    }

    .swiper-slide-active{
      .main-help__slide-title{
        opacity: 1;
      }
      .main-help__slide-text{
        opacity: 1;
      }
      .main-help__slide-line{
        opacity: 1;
      }
    }

    /* &::after{
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background: var(--green);
      position: absolute;
      top: 120px;
      left: 0;
    } */

  }

  &__slide{
    width: 440px;
    cursor: pointer;
    user-select: none;
    //background: #eee;
    @media (max-width: 1099px) {
      width: 320px;
    }
    @media (max-width: 991px) {
      width: 50%;
    }
    @media (max-width: 576px) {
      width: 80vw;
    }
  }

  &__slide-title{
    opacity: 0.33;
    color: #fff;
    font-size: 36px;
    line-height: 1;
    transition-duration: 0.3s;

    @media (max-width: 1099px) {
      font-size: 24px;
    }
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  &__slide-line{
    opacity: 0;
    margin-top: 42px;
    margin-bottom: 60px;
    width: 100px;
    height: 3px;
    background: var(--green);
    transition-duration: 0.3s;

    @media (max-width: 1099px) {
      margin-bottom: 42px;
    }
    @media (max-width: 991px) {
      margin-bottom: 24px;
      margin-top: 24px;
      width: 60px;
    }
  }

  &__slide-text{
    transition-duration: 0.3s;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    line-height: 1.2;
    width: 150%;

    @media (max-width: 1350px) {
      width: 130%;
    }
    @media (max-width: 1099px) {
      font-size: 16px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      width: 127%;
    }
    @media (max-width: 576px) {
      width: calc(100vw - 60px);
    }
  }

  &__swiper-buttons{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 152px;
    height: 64px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      height: 34px;
      width: 86px;
    }
    @media (max-width: 576px) {
      top: -210px;
      right: 0;
      left: auto;
    }
    @media (max-width: 390px) {
      width: 78px;
      top: -212px;
    }

    .swiper-button-prev,
    .swiper-button-next{
      width: 64px;
      height: 64px;
      border-radius: 60px;
      border: 3px solid var(--green);
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      margin: 0;
      user-select: none;
      transition-duration: 0.3s;

      
      @media (max-width: 991px) {
        width: 34px;
        height: 34px;
        border: 2px solid var(--green);
      }

      img{
        @media (max-width: 991px) {
          width: 50%;
        }
      }

      &::after{
        display: none;
      }

      &:hover{
        @media (min-width: 992px) {
          border: 3px solid #1E712E;
        }
      }

    }

  }

}