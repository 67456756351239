

.error-cases{

  &__inner{
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__row{
    display: flex;
    justify-content: center;
    //flex-wrap: wrap;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

  }

  &__card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 40px;

    @media (max-width: 991px) {
      padding: 20px;
    }

    @media (max-width: 768px) {
      padding: 30px;
    }

    @media (max-width: 576px) {
      padding: 20px;
    }
    
    &_square{
      width: 41.66666vw;
      max-width: 800px;
      height: 41.66667vw;
      max-height: 800px;

      @media (max-width: 768px) {
        width: 100vw;
        height: 100vw;
        max-width: 991px;
        max-height: 991px;
      }

    }

    &_wide{
      width: 58.33334vw;
      max-width: 1120px;
      height: 20.833334vw;
      max-height: 400px;

      @media (max-width: 768px) {
        width: 100vw;
        height: 50vw;
        max-width: 991px;
        max-height: 300px;
        min-height: 300px;
      }

      @media (max-width: 576px) {
        min-height: 260px;
      }

    }

    &_more{
      width: 41.66666vw;
      max-width: 800px;
      height: 20.833334vw;
      max-height: 400px;
      background: #fff;
      align-items: center;
      padding: 0;

      @media (max-width: 768px) {
        width: 100vw;
        height: 300px;
        max-width: 991px;
      }
      @media (max-width: 576px) {
        height: 320px;
      }

    }

  }

  &__cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: top;
    z-index: -1;
  }

  &__more{
    border-radius: 200px;
    height: 20.833334vw;
    max-height: 400px;
    width: 20.833334vw;
    max-width: 400px;
    background: linear-gradient(214.8deg, #38A14C -2.06%, #126D81 84.01%);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 576px) {
      width: 320px;
      height: 320px;
    }

  }

  &__type{
    color: #fff;
    font-size: 24px;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 4px 14px;
    display: inline-flex;
    margin-right: auto;
  }

  &__name{
    font-size: 64px;
    color: #fff;
    text-transform: uppercase;

    @media (max-width: 991px) {
      font-size: 50px;
    }

    @media (max-width: 576px) {
      font-size: 36px;
    }

  }

}