


.footer{
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--grad1);

  @media (max-width: 991px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2%;
  }

  &__top{
    width: 100%;
    margin-bottom: 90px;

    @media (max-width: 768px) {
      margin-bottom: 50px;
      order: 1;
    }

    @media (max-width: 576px) {
      margin-bottom: 24px;
    }

  }

  &__left{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 100%;
      order: 3;
    }

  }

  &__right{
    width: 53%;
    max-width: 660px;

    @media (max-width: 768px) {
      width: 100%;
      order: 2;
      max-width: none;
    }

  }

  &__nav{
    display: flex;
    flex-wrap: wrap;
    gap: 24px 2%;
    width: 100%;
    max-width: 430px;

    @media (max-width: 768px) {
      margin-top: 40px;
      gap: 16px 4%;
    }
    @media (max-width: 576px) {
      max-width: 600px;
    }
  }

  &__link{
    width: 49%;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    transition-duration: 0.3s;

    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      font-weight: 400;
      width: 48%;
    }

    &:hover{
      color: var(--green);
    }

  }


  &__contacts{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 430px;
    column-gap: 2%;

    @media (max-width: 768px) {
      margin-top: 40px;
    }
    @media (max-width: 576px) {
      max-width: 600px;
    }
  }

  &__contacts-element{
    width: 49%;

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  &__contacts-title{
    margin-bottom: 8px;
    color: #000;
    color: rgba($color: #fff, $alpha: 0.5);
  }

  &__contacts-link{
    color: #fff;
  }

  &__social{
    width: 100%;
    margin-top: 60px;
    display: flex;
    column-gap: 40px;

    @media (max-width: 768px) {
      column-gap: 24px;
      margin-top: 16px;

      margin-top: 40px;
      justify-content: space-between;
      max-width: 450px;
    }
    @media (max-width: 576px) {
      max-width: 600px;
    }
  }

  &__icon{
    width: 32px;
    height: 32px;
    position: relative;
    cursor: pointer;

    @media (max-width: 991px) {
      width: 26px;
      height: 26px;
    }

    svg{
      position: relative;
      width: 100%;
      height: 100%;

      path{
        transition-duration: 0.3s;
      }

    }

    &:hover{
      svg path {
        fill: var(--green);
      }
    }

  }

}