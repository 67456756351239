

.main-first{
  width: 100%;
  background: var(--grad1);

  &__inner{
    padding-top: 180px;
    padding-bottom: 164px;

    @media (max-width: 991px) {
      padding-bottom: 120px;
    }
    @media (max-width: 768px) {
      padding-top: 120px;
      padding-bottom: 80px;
    }
    @media (max-width: 576px) {
      padding-top: 90px;
      padding-bottom: 50px;
    }
  }

  &__title{
    //font-size: 136px;
    font-size: 9vw;
    @media (min-width: 1500px) {
      font-size: 136px;
    }
    @media (max-width: 390px) {
      font-size: 29px;
    }
    @media (max-width: 330px) {
      font-size: 27px;
    }
  }

  &__middle{
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__satellite{
    width: 50%;
    position: relative;
    //transform: scale(0.80) rotate(15deg) translate(0px,-50px);
    @media (max-width: 1350px) {
      width: 20%;
    }
  }

  &__image{
    display: block;
    position: absolute;
    object-fit: contain;
    //width: 150%;
    //height: 110%;
    //width: 49vw;
    //height: 40vw;
    left: -50px;
    top: -60px;
    animation: satellite 7s ease 0s;
    filter: blur(5);

    @media (max-width: 1440px) {
      left: auto;
      right: 35%;
    }

    @media (max-width: 1199px) {
      width: 650px;
      right: 48%;
      top: -40px;
    }
    @media (max-width: 1099px) {
      width: 600px;
      right: 46%;
    }
    @media (max-width: 991px) {
      width: 54vw;
      top: -40px;
    }
    @media (max-width: 768px) {
      top: -30px;
    }
    @media (max-width: 670px) {
      width: 70vw;
      right: 31%;
      top: 0;
    }
    @media (max-width: 390px) {
      top: 30px;
    }
  }

  &__goods{
    width: 50%;
    display: flex;
    column-gap: 4%;
    margin-top: 290px;
    margin-bottom: 260px;
    @media (max-width: 1350px) {
      width: 80%;
      z-index: 5;
      justify-content: flex-end;
    }
    @media (max-width: 1199px) {
      margin-top: 220px;
      margin-bottom: 220px;
    }
    @media (max-width: 1099px) {
      margin-top: 190px;
      margin-bottom: 190px;
    }
    @media (max-width: 991px) {
      margin-top: 150px;
      margin-bottom: 150px;
      column-gap: 2%;
    }
    @media (max-width: 768px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 670px) {
      flex-wrap: wrap;
      width: 50%;
      margin-top: 50px;
      margin-bottom: 50px;
      row-gap: 24px;
    }
  }

  &__card{
    color: #fff;
    width: 28%;
    min-width: 200px;
    max-width: 200px;
    @media (max-width: 1199px) {
      width: 22%;
      min-width: 150px;
    }
    @media (max-width: 991px) {
      min-width: auto;
      width: 17vw;
    }
    @media (max-width: 670px) {
      width: 100%;
      max-width: 200px;
    }
    &_padding{
      @media (max-width: 991px) {
        padding-left: 2vw;
      }
      @media (max-width: 670px) {
        padding-left: 0;
      }
    }
  }

  &__text{
    font-weight: 400;

    @media (max-width: 1099px) {
      font-size: 14px;
    }
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }

    &_big{
      font-size: 60px;
      @media (max-width: 1350px) {
        font-size: 50px;
      }
      @media (max-width: 1099px) {
        font-size: 44px;
      }
      @media (max-width: 991px) {
        font-size: 38px;
      }
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
    &_mid{
      font-size: 28px;
      margin-bottom: 20px;

      @media (max-width: 1350px) {
        font-size: 22px;
      }
      @media (max-width: 1099px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
      @media (max-width: 991px) {
        font-size: 18px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
      @media (max-width: 670px) {
        margin-bottom: 6px;
      }
    }
  }

  &__bottom{
    
  }

  &__flex{
    display: flex;
    justify-content: space-between;
  }

  &__subtitle{
    max-width: 640px;
    font-size: 7vw;
    @media (min-width: 1360px) {
      font-size: 96px;
    }
  }

  &__btn{
    margin-top: auto;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }

    
    &_second{
      @media (max-width: 991px) {
        font-size: 16px;
        min-width: auto;
        max-width: 350px;
        width: 100%;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 14px;
        max-width: 250px;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }

    &_mobile{
      display: none;
      @media (max-width: 576px) {
        display: block;
        margin-top: 32px;
        margin-bottom: 0;
        height: 42px;
      }
    }

  }

  &__box{
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    //padding-left: 220px;
    @media (max-width: 768px) {
      gap: 40px;
      margin-top: 60px;
    }
    @media (max-width: 767px) {
      gap: 24px;
    }
    @media (max-width: 576px) {
      margin-top: 30px;
    }
  }

  &__line{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  &__name{
    width: 50%;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    //width: 450px;
    padding-left: 220px;
    line-height: 1;
    @media (max-width: 1350px) {
      font-size: 24px;
    }
    @media (max-width: 1199px) {
      padding-left: 150px;
      font-size: 20px;
    }
    @media (max-width: 1099px) {
      font-size: 18px;
    }
    @media (max-width: 991px) {
      padding-left: 10vw;
      font-size: 14px;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      font-size: 18px;
      font-weight: 400;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      font-weight: 400;
    }

  }

  &__stack{
    display: flex;
    width: 50%;
    gap: 12px;
    @media (max-width: 1366px) {
      gap: 10px;
    }
    @media (max-width: 1099px) {
      gap: 8px;
    }
    @media (max-width: 991px) {
      gap: 5px;
      width: 52%;
    }
    @media (max-width: 767px) {
      width: 100%; 
    }
  }

  &__tech{
    padding: 12px 19px;
    border: 1px solid #fff;
    box-shadow: 0 0 0 0.5px #fff;
    border-radius: 50px;
    //font-weight: 600;
    color: #fff;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    transition-duration: 0.3s;

    &:hover{
      border: 1px solid var(--green);
      box-shadow: 0 0 0 0.5px var(--green);
    }

    @media (max-width: 1350px) {
      font-size: 14px;
    }
    @media (max-width: 1199px) {
      padding: 10px 17px;
      font-size: 13px;
    }
    @media (max-width: 1099px) {
      padding: 10px 15px;
    }
    @media (max-width: 991px) {
      font-size: 10px;
      padding: 8px 12px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
      padding: 10px 15px;
    }
    @media (max-width: 576px) {
      font-size: 10px;
      padding: 8px 12px;
      box-shadow: none;
    }
    @media (max-width: 420px) {
      padding: 4px 7px;
      font-size: 9px;
      border-radius: 9px;
    }

  }

}





@keyframes satellite {
  0%{
    //transform: scale(1) rotate(0deg) translate(0,0);
    transform: scale(0.4) rotate(19deg) translate(-100px,-100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  30%{
    transform: scale(0.4) rotate(19deg) translate(-100px,-100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  40%{
    transform: scale(0.4) rotate(19deg) translate(-100px,-100px);
    filter: blur(5px);
    opacity: 0.5;
  }
  75%{
    transform: scale(1.1) rotate(-3deg) translate(0,5px);
    filter: blur(0px);
    opacity: 1;
  }
  100%{
    transform: scale(1) rotate(0deg) translate(0,0);
    filter: blur(0px);
    opacity: 1;
  }
}