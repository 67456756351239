

.modal{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background: #00000099;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  opacity: 0;
  z-index: -1;
  transition-duration: 0.3s;
  padding: 7vh 0;

  @media (max-width: 576px) {
    padding: 40px 0 100px 0;
  }

  &_open{
    opacity: 1;
    z-index: 30;
  }

  &__body{
    width: 96%;
    max-width: 1080px;
    background: #fff;
    padding: 100px 50px 50px 50px;
    border-radius: 30px;
    position: relative;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 30px;
    }
    @media (max-width: 576px) {
      padding: 30px 20px;
      border-radius: 10px;
    }

    &_main{

    }

    &_success{
      padding-top: 140px;
      padding-bottom: 140px;
      @media (max-width: 768px) {
        padding: 120px 30px;
      }
      @media (max-width: 576px) {
        padding: 70px 30px;
        border-radius: 10px;
      }
    }

    &_hide{
      display: none;
    }

  }

  &__close{
    position: absolute;
    width: 45px;
    height: 45px;
    top: 40px;
    right: 40px;
    padding: 5px;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;

    @media (max-width: 768px) {
      top: 30px;
      right: 30px;
      width: 35px;
      height: 35px;
    }
    @media (max-width: 576px) {
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
    }

    img{
      width: 90%;
      height: 90%;
      object-fit: contain;
    }

    &:hover{
      opacity: 0.5;
    }

  }

  &__title{
    font-size: 72px;
    margin-bottom: 20px;
    
    

    @media (max-width: 768px) {
      font-size: 52px;
      width: calc(100% - 60px);
    }
    @media (max-width: 576px) {
      font-size: 29px;
    }

    &_success{
      width: 100%;
      span{
        text-align: center;
        width: 100%;
      }
    }

  }

  &__text{
    font-size: 16px;
    color: var(--black);

    &_success{
      text-align: center;
    }

  }

  &__form{
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 2%;

    @media (max-width: 768px) {
      margin-top: 30px;
      row-gap: 21px;
    }
  }

  
  &__input{
    z-index: 1;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba($color: #000, $alpha: 0.3);
    transition-duration: 0.3s;

    &_third{
      width: 32%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &_wide{
      width: 100%;
    }

    &_active{
      border-bottom: 1px solid var(--black);
    }

    &.form__input_active{
      border-bottom: 1px solid var(--black);
    }

    input{
      font-size: 20px;
      color: var(--black);
      padding: 8px 0;
      padding-right: 30px;
      width: 100%;
      background: transparent;
      &:focus{
        outline: 0;
        outline-offset: 0;
      }
    }

  }

  &__files{
    position: relative;
    width: 100%;
    border-radius: 30px;
    background: #f0f0f0;
    padding: 44px 50px;
    @media (max-width: 576px) {
      padding: 30px 20px;
      border-radius: 10px;
    }
  }

  &__files-input{
    position: absolute;
    top: -30px;
    left: 0;
    display: none;
  }

  &__files-list{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    //max-height: 150px;
    //overflow-y: auto;

    &_margin{
      margin-bottom: 40px;
    }

  }

  &__files-line{
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__files-name{
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 440px) {
      width: calc(100% - 50px);
    }
  }

  &__files-del{
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
    text-align: center;
  }

  &__files-label{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    line-height: 30px;
    span{
      font-size: 22px;
      margin-right: 10px;
      padding-top: 3px;
      line-height: 27px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 1.4;
    }
  }

  &__placeholder{
    display: flex;
    font-weight: 300;
    color: var(--black);
    align-items: center;
    transition-duration: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    font-size: 16px;
    line-height: 20px;

    &_active{
      font-size: 16px;
      position: absolute;
      margin-top: -30px;
    }

    &_error{
      color: #FF3B3B;
    }

  }

  &__btn{
    width: 100%;
    @media (max-width: 768px) {
      min-width: auto;
      max-width: none;
    }
  }

  &__check{
    color: var(--green);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    transition-duration: 0.3s;
    opacity: 0;

    &_active{
      opacity: 1;
    }

  }

  &__note{
    color: var(--black);
    margin-top: -30px;
    font-size: 15px;

    @media (max-width: 768px) {
      margin-top: 0;
    }

    &_subscribe{
      margin-top: 0px;
    }

    a {
      color: var(--black);
      text-decoration: underline;
    }
  }

}