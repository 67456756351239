

.form{
  width: 100%;

  &__top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    @media (max-width: 991px) {
      margin-bottom: 42px;
    }
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__title{
    width: 100%;
    max-width: 267px;
    color: #fff;
    font-size: 24px;

    @media (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }

    &_thin{
      font-weight: 300;
      font-size: 20px;

      @media (max-width: 991px) {
        font-size: 14px;
      }
      @media (max-width: 576px) {
        font-size: 12px;
      }
      
    }

  }

  &__body{
    display: flex;
    flex-wrap: wrap;
    row-gap: 90px;
    column-gap: 16px;

    @media (max-width: 991px) {
      row-gap: 40px;
      column-gap: 12px;
    }

    @media (max-width: 576px) {
      row-gap: 20px;
    }

    &_subscribe{
      row-gap: 42px;
      @media (max-width: 768px) {
        row-gap: 30px;
      }
    }

  }

  &__input{
    z-index: 1;
    position: relative;
    border: none;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
    transition-duration: 0.3s;

    &_half{
      width: calc(50% - 8px);
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &_wide{
      width: 100%;
    }

    &_active{
      border-bottom: 1px solid rgba($color: #fff, $alpha: 1);
    }

    input{
      font-size: 20px;
      color: #fff;
      padding: 8px 0;
      padding-right: 30px;
      width: 100%;
      background: transparent;

      @media (max-width: 991px) {
        font-size: 12px;
      }

      &:focus{
        outline: 0;
        outline-offset: 0;
      }
    }

  }

  &__placeholder{
    display: flex;
    font-weight: 300;
    color: #fff;
    align-items: center;
    transition-duration: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 991px) {
      font-size: 12px;
    }

    &_active{
      font-size: 16px;
      position: absolute;
      margin-top: -30px;

      @media (max-width: 991px) {
        font-size: 12px;
        margin-top: -20px;
      }
    }

    &_error{
      color: #FF3B3B;
    }

  }

  &__btn{
    width: 100%;
    margin-top: 30px;
    min-width: auto;

    @media (max-width: 991px) {
      height: 42px;
      font-size: 14px;
      padding: 12px;
      margin-top: 10px;
    }
    @media (max-width: 576px) {
      max-width: none;
    }

  }

  &__check{
    color: var(--green);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    transition-duration: 0.3s;
    opacity: 0;

    &_active{
      opacity: 1;
    }

  }

  &__note{
    color: #fff;
    max-width: 550px;
    margin-top: -40px;

    @media (max-width: 991px) {
      font-size: 10px;
      margin-top: -20px;
    }
    @media (max-width: 576px) {
      margin-top: 0;
      
    }

    &_subscribe{
      margin-top: 0px;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }


}