

.sticky{

  height: 5000px;

  @media (max-width: 768px) {
    height: 5500px;
  }
  
  &_long{
    height: 9000px;
    @media (max-width: 576px) {
      height: 8000px;
    }
  }

  &__inner{
    position: sticky;
    top: 140px;
    padding-bottom: 1px;

    @media (max-width: 576px) {
      top: 100px;
    }

  }

}