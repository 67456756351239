

.steps{
  //position: sticky;
  //top: 120px;

  /* &_fixed{
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 99;
  } */

  &__inner{
    margin-top: 160px;
    margin-bottom: 160px;
    
    //height: 5000px;
    
    position: relative;

    @media (max-width: 991px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
    @media (max-width: 768px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
      margin-bottom: 60px;
    }

  }

  &__title{
    &_sm{
      font-size: 72px;

      @media (max-width: 991px) {
        font-size: 50px;
      }
      @media (max-width: 768px) {
        font-size: 40px;
      }
      @media (max-width: 576px) {
        font-size: 30px;
      }
      @media (max-width: 390px) {
        font-size: 24px;
      }
    }
  }

  &__subtitle{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__sticky{
    
    //position: sticky;

    //top: 140px;

    //height: 550px;
    z-index: 9;

    @media (max-width: 991px) {
      //height: 480px;
    }
    @media (max-width: 576px) {
      height: 400px;
      //top: 90px;
    }

    &_main{
      @media (max-width: 576px) {
        height: 360px;
      }
    }
  }

  &__top{
    margin-bottom: 90px;
    display: flex;
    justify-content: space-between;
    //align-items: flex-end;
    align-items: center;
    column-gap: 16px;
    //height: 192px;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
    @media (max-width: 576px) {
      margin-bottom: 32px;
    }
  }

  &__order{
    padding-bottom: 20px;
    max-width: 434px;
    height: 100%;
    @media (max-width: 1199px) {
      padding-bottom: 0;
    }
    @media (max-width: 991px) {
      padding-bottom: 0;
    }
    @media (max-width: 768px) {
      max-width: 45%;
    }
  }

  &__btn{
    @media (max-width: 991px) {
      padding: 14px;
      font-size: 14px;
      max-width: 340px;
      min-width: auto;
      width: 100%;
    }
    @media (max-width: 576px) {
      position: absolute;
      left: 0;
      bottom: 0px;
      //top: 320px;
      width: 100%;
      max-width: 100%;
    }
    &_main{
      @media (max-width: 576px) {
        bottom: 0px;
        //top: 320px;
      }
    }
  }

  &__text{
    font-size: 20px;
    margin-bottom: 40px;

    @media (max-width: 1199px) {
      margin-bottom: 24px;
    }
    @media (max-width: 991px) {
      max-width: 340px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 12px;
    }
    @media (max-width: 576px) {
      font-size: 10px;
      margin-bottom: 0;
    }
  }

  &__box{
    width: 100%;
    position: relative;
    overflow: clip;
  }

  &__floor{
    width: 100%;
  }

  &__row{
    width: 200%;
    display: flex;
    justify-content: center;
    position: relative;
    transition-duration: 0.5s;
    transform: translateX(16.6666%);

    @media (max-width: 991px) {
      transform: translateX(0);
      width: 300%;
    }
    @media (max-width: 450px) {
      width: 450%;
    }

    &_step-1{
      transform: translateX(0%);
      @media (max-width: 576px) {
        transform: translateX(-16.6666%);
      }
    }

    &_step-2{
      transform: translateX(-16.6666%);
      @media (max-width: 576px) {
        transform: translateX(-33.3333%);
      }
    }

    &_step-3{
      transform: translateX(-33.3333%);
      @media (max-width: 576px) {
        transform: translateX(-50%);
      }
    }

    &_step-4{
      transform: translateX(-50%);
      @media (max-width: 576px) {
        transform: translateX(-66.6666%);
      }
    }

  }

  &__progress{
    height: 1px;
    background: var(--gray);
    position: absolute;
    width: 83.3333%;
    left: 0;
    top: 123px;
    z-index: -1;
    @media (max-width: 576px) {
      top: 83px;
    }
  }

  &__active{
    height: 3px;
    width: 20%;
    margin-top: -1px;
    background: var(--green);
    position: absolute;
    transition-duration: 0.5s;

    &_step-1{
      transform: translateX(100%);
    }

    &_step-2{
      transform: translateX(200%);
    }

    &_step-3{
/*       transform: translateX(203%);
      width: 24.8%; */
      transform: translateX(300%);
    }

    &_step-4{
/*       transform: translateX(303%); */
      transform: translateX(400%);
    }

  }

  &__block{
    width: 33.333%;

    @media (max-width: 991px) {
      width: 50%;
    }
    @media (max-width: 450px) {
      width: 75%;
    }

    &_50{
      width: 50%;
    }

  }

  &__number{
    font-size: 80px;
    line-height: 1;
    font-weight: 700;
    color: var(--black);
    transition-duration: 0.5s;

    @media (max-width: 576px) {
      font-size: 40px;
    }

    &_active{
      color: var(--green);
    }

    span{
      font-size: 20px;
      color: var(--gray);
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

  }

  &__dot{
    background: var(--green);
    width: 15px;
    height: 15px;
    border-radius: 50px;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  &__description{
    font-size: 20px;
    max-width: 70%;
    @media (max-width: 768px) {
      max-width: 90%;
    }
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }



}