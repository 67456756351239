

.error-first{
  position: relative;
  background: var(--grad1);

  &__inner{
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text{
    font-size: 18px;
    font-weight: 300;
    color: #fff;

    &_big{
      font-size: 24px;
      font-weight: 600;
    }

  }

  &__title{
    font-size: 246px;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 150px;
    }

  }

  &__cases{
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    cursor: pointer;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: calc(100%);
    padding: 0 20px;

    @media (max-width: 576px) {
      max-width: 290px;
      font-size: 16px;
      bottom: 24px;
    }

  }

}