

.dropdown{

  &__line{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 80px;
    padding-bottom: 52px;
    padding-top: 32px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E7E7E7;

    @media (max-width: 1199px) {
      column-gap: 50px;
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      row-gap: 16px;
    }
    @media (max-width: 576px) {
      padding-top: 22px;
      padding-bottom: 32px;
      margin-bottom: 10px;
    }

  }

  &__img{
    width: 375px;
    flex-shrink: 0;
    transition-duration: 0.3s;

    @media (max-width: 470px) {
      width: 100%;
    }

    img{
      transition-duration: 0.3s;
      width: 100%;
      height: 156px;
      object-fit: cover;
      object-position: right center;
      border-radius: 10px;

      @media (max-width: 991px) {
        height: auto;
        max-height: 160px;
      }

    }

    &_open{

      img{
        height: 350px;
        @media (max-width: 991px) {
          height: 260px;
          max-height: 260px;
        }
      }
      
    }

  }

  &__content{
    max-width: 768px;

    @media (max-width: 991px) {
      width: 100%;
      padding-right: 60px;
      max-width: none;
    }
    @media (max-width: 576px) {
      padding-right: 0;
    }
  }

  &__title{
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 576px) {
      padding-right: 60px;
      min-height: 32px;
    }
  }

  &__btn{
    width: 44px;
    height: 44px;
    border-radius: 50px;
    background: #000;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;

    @media (max-width: 991px) {
      position: absolute;
      right: 0;
      top: 48px;
    }
    @media (max-width: 576px) {
      width: 32px;
      height: 32px;
      top: 36px;
    }

    &_image{
      @media (max-width: 991px) {
        top: 32px;
      }
      @media (max-width: 576px) {
        top: 22px;
      }
      @media (max-width: 470px) {
        top: 200px;
      }

      &.dropdown__btn_open{
        //top: 300px;
      }

    }

    &_open{
      background: var(--green);
      img{
        transform: scaleY(-1);
      }
    }
    
  }

  &__slide{
    height: 0;
    overflow: clip;
    transition-duration: 0.3s;
  }

  &__hidden{
    
  }

  .content-cms{
    p{
      color: var(--black);
      line-height: 22px;
      font-size: 16px !important;
    }
    ul{

      li{
        margin-bottom: 0;
      }
    }
    a{
      text-decoration: underline;
      color: var(--green);
    }
  }
  
}